.b-menu_panel {
	@include media(lg-up) {
		html.m-has_dialog & { //stylelint-disable-line
			overflow-y: scroll; // we need a wrapper to handle scroll / no scroll content width bumping
		}
	}

	&-wrapper {
		@include media(md-down) {
			@include g-dialog_backdrop;
		}
	}

	&-inner {
		@include media(md-down) {
			background-color: $color-bg-panel;
			bottom: 0;
			height: 100%;
			left: 0;
			max-width: 400px;
			overflow: hidden;
			position: fixed;
			top: 0;
			transform: translateX(-100%);
			transition-property: transform;
			visibility: hidden;
			width: 90vw;
			z-index: z(modal);

			@include rtl {
				left: initial;
				right: 0;
				transform: translateX(100%);
			}

			&.m-closed {
				transition: $motion-ease-popups;
			}

			&.m-opened {
				box-shadow: $depth-3;
				transform: translateX(0);
				transition: $motion-ease-popups;
				visibility: visible;

				@include rtl {
					transform: translateX(0);
				}
			}

			&.m-no_transition {
				transition: none !important; // stylelint-disable-line
			}
		}
	}

	&-head {
		display: none;

		@include media(md-down) {
			align-items: center;
			background-color: $color-bg-panel-head;
			display: flex;
			justify-content: space-between;
			min-height: 48px;
		}
	}

	&-title {
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 1px;
		line-height: 1.14;
		text-align: center;
		text-transform: uppercase;
		width: 100%;
	}

	&-back {
		@include g-button_iconed;
	}

	&-footer {
		margin-top: 8px;

		@include media(lg-up) {
			display: none;
		}
	}

	&-close {
		@include g-button_iconed(48px, 56px);

		margin-inline-start: auto;
		padding: 16px 20px;

		svg {
			height: 16px;
			width: 16px;
		}
	}
}
