.b-nav_aux {
	@include g-section_holder;

	color: $color-text-dimmed;
	margin-bottom: 20px;
	margin-top: 20px;

	@include media(sm) {
		margin-bottom: 20px;
		margin-top: 20px;
	}
}
