.b-header_login {
	@include media(md-down) {
		@include g-link_hamburger;

		box-shadow: none;
		padding: 0 4px;
		text-transform: none;
	}

	&-icon {
		@include g-button_iconed(40px, 40px);

		@include media(lg-up) {
			height: 48px;
			width: 48px;
		}

		@include hover-supported {
			svg {
				transform: translateY(0);
				transition: transform $motion-ease;
			}

			&:hover,
			&.m-hover {
				svg {
					transform: translateY(-4px);
				}
			}
		}
	}

	&-caption {
		display: flex;

		@include media(lg-up) {
			display: none;
		}

		.b-header_actions-item & { // we should not show links if in header, but on hamburger it should be shown
			@include media(md) {
				display: none;
			}
		}
	}

	&-divider {
		margin: rh(0 1);
	}

	&-link {
		color: inherit;
		white-space: nowrap;
	}
}
