body {
	overflow: hidden;
	pointer-events: none;
	visibility: hidden;
}

// show header only
.b-header_utility,
.l-header,
.b-menu_panel {
	pointer-events: all;
	visibility: visible;
}
