.b-promo_tiles_grid {
	&-title {
		@include g-heading_2;

		margin: 40px 0 20px;
		text-align: center;

		@include media(sm) {
			margin: 30px 0 15px;
		}
	}

	&-content {
		@include g-grid;
	}

	&-item {
		display: block;
		grid-column: span 3;

		@include media(md-up) {
			.b-promo_tiles_grid.m-lg_2 & {
				grid-column: span 6;
			}

			.b-promo_tiles_grid.m-lg_3 & {
				grid-column: span 4;
			}

			//.b-promo_tiles_grid.m-lg_4 & {
			//	grid-column: span 3;
			//}
		}

		@include media(sm) {
			grid-column: span 3;

			&:not(:last-child) {
				margin-bottom: var(--spacer-mobile, 0px);
			}

			.b-promo_tiles_grid.m-sm_1 & {
				grid-column: span 6;
			}

			//.b-promo_tiles_grid.m-sm_2 & {
			//	grid-column: span 3;
			//}
			.b-promo_tiles_grid.m-sm_2_1 &:nth-child(3n) {
				grid-column: span 6;
			}
		}
	}

	&-item_link {
		@include g-link;

		display: block;
		line-height: 1.33;
	}
}

.b-promo_tiles_grid.m-custom_menu {
	margin: rh(2) auto rh(10);
	max-width: grid-span(8);
	width: 100%;
}
