/*md

# b-promo_tiles_grid

This is special type of banners that is provided as single component and mostly
used as set of tiles with links to categories or subcategories.

## Example

```html_example
<section class="b-promo_tiles_grid">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

*/

.b-promo_tile {
	display: grid;
	height: 100%;

	&-picture {
		@include g-image_container(_container, aspect-ratio(0.7, 1));

		grid-column: 1 / 2;
		grid-row: 1 / 2;

		img {
			@include g-image_container(_img);
		}
	}

	&-caption {
		align-items: center;
		display: flex;
		flex-direction: column;
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		justify-content: flex-end;
		padding: rh(12 3);
		text-align: center;
		z-index: 1;

		@include media(sm) {
			padding-bottom: rh(9);
			padding-top: rh(9);
		}

		.b-promo_tile.m-caption_below & {
			grid-row: 2 / 2;
			padding-bottom: 20px;
			padding-top: 20px;

			@include media(sm) {
				padding-bottom: 10px;
				padding-top: 10px;
			}
		}

		.b-button { // Case with button inside
			margin-top: rh(5);
		}
	}

	&-title {
		@include g-heading_5;

		line-height: 1.33;
	}
}

.b-promo_tiles_grid.m-custom_menu {
	.b-promo_tile-title {
		font-size: 16px;
	}
}

.l-plp_grid .b-promo_tile {
	.b-promo_tile-title {
		@include g-heading_3;
	}
}
