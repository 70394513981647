.b-header_actions {
	align-items: center;
	color: adjust-color-to-bg($color-bg-header-line-1);
	display: flex;

	&-item {
		&.m-account,
		&.m-wishlist {
			@include media(sm) {
				display: none;
			}
		}

		&.m-search,
		&.m-account,
		&.m-wishlist,
		&.m-storelocator,
		&.m-minicart {
			@include media(md-down) {
				margin-inline-start: rh(2);
			}
		}

		&.m-hamburger {
			@include media(lg-up) {
				display: none;
			}
		}

		&.m-locale {
			@include media(md-down) {
				display: none;
			}
		}

		&.m-storelocator {
			@include media(md-down) {
				display: none;
			}
		}
	}
}

.l-header-right .b-header_actions {
	justify-content: flex-end;
	width: 100%;
}
