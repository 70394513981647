.b-menu_bar {
	background-color: $color-bg-header-line-2;
	position: relative;
	transition: background-color $motion-ease;
	z-index: z(menu-bar);

	&-item {
		&.m-hidden {
			display: none;
		}
	}

	&-link {
		display: block;
		letter-spacing: 1px;
		line-height: 1.14;
		padding: 24px 32px;
		position: relative;
		text-decoration: none;
		text-transform: uppercase;

		@include media(md-down) {
			@include g-link_hamburger($_divider: true);
		}

		@include media(lg-up) {
			@include g-link;

			&.m-highlight {
				color: $color-accent;
			}
		}
	}

	&-container {
		@include media(lg-up) {
			@include g-section_holder_header;
		}
	}

	&-inner {
		@include media(md-down) {
			margin: 0;
		}

		@include media(lg-up) {
			display: flex;
			justify-content: center;
		}
	}

	&-link_highlight {
		@include media(md-down) {
			@include g-link_hamburger(highlight);
		}
	}

	&-link_text {
		@include media(lg-up) {
			.m-highlight &::before {
				background: $color-accent;
			}
		}
	}

	&-flyout {
		@include media(lg-up) {
			background-color: $color-bg;
			border-top: 1px solid $color-divider;
			box-shadow: 0 50vh 0 50vh rgba($color-bg-overlay, 0.4);
			color: $color-text;
			display: none;
			left: 0;
			max-height: 70vh;
			min-height: 300px;
			overflow-y: auto;
			position: absolute;
			right: 0;
			top: 100%;

			&[aria-hidden='false'] {
				display: block;
			}
		}
	}

	&-flyout_inner {
		@include media(lg-up) {
			@include g-section_holder;

			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			padding-bottom: 10px;
			padding-top: 30px;
			position: relative;

			.content-asset { // need to fix this content asset wrapper for flyout with customHTML asset
				width: 100%;
			}
		}
	}

	&-flyout_column {
		@include media(lg-up) {
			flex-basis: grid-span(2, 'lg');
			padding-bottom: 20px;
			padding-right: grid-gutter('lg');
		}

		&.m-hidden {
			display: none;
		}
	}

	&-flyout_close {
		@include media(md-down) {
			display: none;
		}

		@include media(lg-up) {
			@include g-button_iconed;

			position: absolute;
			right: 8px;
			top: 14px;

			@include rtl {
				left: 8px;
				right: initial;
			}
		}

		@include hover-supported {
			svg {
				transform: translateY(0);
				transition: transform $motion-ease;
			}

			&:hover,
			&.m-hover {
				svg {
					transform: translateY(-4px);
				}
			}
		}

		svg {
			height: 16px;
			width: 16px;
		}
	}

	&-flyout_link_wrapper {
		display: block;
		width: 100%;
	}
}
