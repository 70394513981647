/*md

# b-promo_line

Promo-line is classic text promo banner. It scratched to full viewport width and
placed right under the header.

It consist from several elements, but has only one to place text into it `b-promo_line-inner`.

It could consist inline images, rich formatting text (`strong`, `em`) or links.

## Example

```html_example
<div class="b-promo_line m-header">
	<p class="b-promo_line-inner">
		Get 20% off everything with code: <strong>ILOVE20</strong> <a href="$url('Product-Show', 'pid', 'product-1')$">Buy now</a>
	</p>
</div>
```

*/

.b-promo_line {
	background-color: $color-bg-header-promo;
	color: $color-fg-header-promo;
	font-size: 16px;
	font-weight: 500;
	padding: rh(7 0);
	text-align: center;

	@include media(md-down) {
		padding: rh(3 0);
	}

	&.m-search_results {
		background-color: $color-bg-search-promo;
		color: $color-fg-search-promo;

		@include media(sm) {
			display: none;
		}
	}

	.m-has_dialog &.m-header { // we need a wrapper to handle scroll / no scroll content width bumping
		overflow-y: scroll;
	}

	&-inner {
		@include g-section_holder;
	}

	a {
		color: inherit;
		text-decoration: underline;

		&:hover {
			color: $color-action;
			text-decoration: none;
		}
	}
}
