/*md
@no-stat

# Breakpoints

## Boilerplate breakpoints

Boilerplate has 4 main breakpoints that targeted to [supported devices](https://confluence.ontrq.com/display/RSB/SFRA+BP+-+Supported+Browsers+and+Devices)
 - iPhone X, iPad, MS Windows desktop / Macbook Pro retina

** Please not iPad landscape - is LG breakpoint **

[See more info](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

## Supported screen resolutions

Boilerplate is come "Retina ready". It supports MDPI and XHDPI pixel density across all site.

| Device             | Screen Resolution, CSS pixels | Pixel density |
|--------------------|-------------------------------|---------------|
| Desktop Windows PC | 1920x1080                     | MDPI          |
| Macbook pro 13     | 1280x800 / 1440x900           | XHDPI         |
| iPad Air 2         | 1024x768                      | XHDPI         |
| iPhone X           | 375x812                       | XHDPI         |
| Samsung Galaxy S9  | 360x740                       | XHDPI         |

*/
/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS,which puts the properties and values you are changing right next
to each other.
That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in a different file.

## Configuration

**Site Layout Conception** details with examples you can find [here](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1024px)',
	lg-down: 'screen and (max-width: 1367px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
/*md
@no-stat

# Palette

This is palette settings for project/brand. It divided into 2 main categories palette and applied color.

* Palette is general set of colors. It could be used directly if you do not have themes.
* Applied colors designed as layer of abstraction to have ability to overwritten on brand level.

*/
/*md
@no-stat

# Globals variables

This variables are set of different global settings that is used across sets of components.

It include:

* globals
* depth of components (box-shadow)
* motion of components

*/
/*md
@no-stat

# Rh (Indents rhythm)

This function is designed to keep consistency of vertical and horizontal indents in the project.

Not all values are identical in design, sometimes 20px become 21px, 19px, 22px etc. It does not make
any sense to implement it as is. To keep indents consistent we need to round this values to 4px steps.

Ex: in design 22px / 19px -> rh(5) => 20px; in design 23px -> rh(6) => 24px etc.

This is alternate approach to `$space-md: 10px; $space-lg: 20px;`.

Designers should use the rhythm in his work.

## Usage

```scss
.component {
	@include rh(2); // => 8px
	@include rh(2 4 0); // => 8px 16px 0
}
```
*/
/*md
@no-stat

# Z-indexes

Z-index is an inherently tricky thing, and maintaining z-index order in a complex layout is difficult.
With different stacking orders and contexts, keeping track of them as their numbers increase can be hard.
<br />
<br />
We use sass function to help manage z-indexes from single place.
The most important requirement of this technique is sticking to it.
Any rogue hard-coded z-index values could compromise the integrity of those derived from your list.

## Usage

**We don't use hardcoded integer `z-index` values. Instead, we use indexes from the map `$z-indexes`**

### 1. Set up `$z-indexes` map
```scss
$z-indexes: (
    components: (
        component_name: (),
        checkbox: (
            before: (),
            after: (),
            icon: (),
        )
    ),
    content: (),
    popup-menu: ()
);
```

### 2. Add values in SCSS classes using `z()` function

#### Global components
```scss
.b-components { z-index: z(components); }
.b-content { z-index: z(content); }
.b-pop_up-menu { z-index: z(popup-menu); }
```

#### Inside a component
```scss
.b-component_name { z-index: z(components, component_name); }
.b-checkbox {
    &-before { z-index: z(components, checkbox, before); }
    &-after { z-index: z(components, checkbox, after); }
    &-icon { z-index: z(components, checkbox, icon); }
}
```

### 3. Get resulting CSS
```scss
.b-components { z-index: 1; }
.b-content { z-index: 2; }
.b-pop_up-menu { z-index: 3; }

.b-component_name { z-index: 1; }
.b-checkbox-before { z-index: 1; }
.b-checkbox-after { z-index: 2; }
.b-checkbox-icon { z-index: 3; }
```

*/
/*md
@no-stat

# Grids

## How to setup grids config for project

### Several grid configs for project

We can use several grid configs per project. For that, we need to add a new grid name to the `$grids` map with settings.

### Gaps / margin / column span configuration:

```scss
$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	)
);
```

### Grid-span configuration

Please see details [grid-span](01-core-functions-grid-span.html)

## Work with grids

### Development approaches

#### 1. Using `g-grid` mixin

With features of `display: grid`. Please see [g-grid](02-components-g-grid.html) details.

#### 2. Using `grid-span` function

Could be used in conjunction with different display properties while maintaining their common features(floating, centering, etc.). Please see [grid-span](01-core-functions-grid-span.html) details.

### Get gaps / margin / column span

For that we have the next grid functions in `_grids_tools.scss`:
- grid-gutter
- grid-margin
- grid-columns

Please see [grid functions](00-configuration-grids_tools.html) details with usage examples.

### Examples of usage

Please see [ready-made tools](05-blocks-guide-l-cols.html) details.

*/
/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get parameters from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/
/*md
@no-stat

# adjust-color-to-bg

This function used to adjust color depending on provided background color. It use
luminance human persived criteria as breakpoint for colors
[See more details](http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef).

It is especially useful for crating flexible themes.

## Arguments

```
$backgroundColor - bg color
$colorInverse - color if bg is dark. If not provided would return $color-white
$colorNormal - color if bg is light. If not provided would return $color-text

adjust-color-to-bg($backgroundColor, $colorInverse, $colorNormal)
```

## Usage

```scss
.component {
	color: adjust-color-to-bg($color-bg-header-line-1);

	// => results default 'white' if background dark
	// => results default 'black' if background is light
}

.component-custom-inverse-color {
	color: adjust-color-to-bg($color-bg-footer, grey);

	// => results 'grey' if background dark
	// => results default 'black' if background is light
}

.component-all-custom-colors {
	color: adjust-color-to-bg($color-bg-footer, green, red);

	// => result 'green' if background dark
	// => result 'red' if background is light
}
```

Based on Hugo Giraudel [work](https://css-tricks.com/snippets/sass/luminance-color-function/)
*/
/*md
@no-stat

# grid-span

`grid-span` function returns value which could be used as **width, max-witdth, flex-basis, etc.**

### Parameters
```scss
@function grid-span($column: 1, $break: 'lg', $with-gutter: false, $grid: 'default')
```

## Examples

### Flex-basis example

```scss
.b-grid {
	display: flex;

	.b-grid__item {
		flex-basis: grid-span($column: 3);
	}
}
```

### Floated items example

```scss
.b-grid {
	.b-grid__item {
		float: left;
		width: grid-span($column: 2);
	}
}
```

### Inline-block items example

```scss
.b-grid {
	.b-grid__item {
		display: inline-block;
		max-width: grid-span($column: 2);
	}
}
```

*/
/*md
@no-stat

# aspect-ratio

This function used to get percentage value of aspect ratio color to use in `padding` to
create container for images.

This technique used to prevent content bouncing during load and create layout shifts.

Calculation. 16:9 Aspect Ratio would result `(9 / 16) * 100 = 0.5625%`.

See proposed [specs](https://drafts.csswg.org/css-sizing-4/#aspect-ratio)

## Arguments

```
$width - width of element
$height - height of element

=> percentage

aspect-ratio($width, $height)
```

## Usage

```scss
.component {
	padding-bottom: aspect-ratio(16, 9);
	padding-bottom: aspect-ratio(1920, 1080);
	padding-bottom: aspect-ratio(1920px, 1080px);
}

.b-suggestions-item_image {
	@include g-image_container(_container, aspect-ratio(16, 9));

	img {
		@include g-image_container(_img);
	}
}
```

*/
/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/
.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

/*md
@no-stat

# Hover-supported

This mixin is designed to address iOS standard behavior of first tap - hover,
second tap - click that is engaged when control has hover styles applied.

This is critical for functionality like back-top-button. If we apply hover styles as is.
It would be activated only after second tap.

If rules are wrapped into this media it applied only in case if device have fine
pointer mechanism. [See more info](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer).

Please note about mixed input devices - touch screen + mouse + touchpad,
touchpad + trackpoint, touch screen + stylus ("apple pencil") etc. -
sometimes browser do not report it properly, so logic should be builded around
exclusions.

## Usage

```scss
.component {
	@include hover-supported {
		&:hover {
			// Hover styles that should not be applied to touch
		}
	};
}
```
*/
/*md
@no-stat

# RTL selector

This mixin is designed to alter styles for RTL languages.

It mostly needed for alter position:absolute left|right coords, but could be used
as facade for different selectors.

## Usage

```scss
.component {
	left: 0;
	@include rtl {
		left: initial;
		right: 0;
	};
}
```
*/
/* stylelint-disable selector-no-vendor-prefix */
/*md

# g-button

Designed to provide same styles of buttons across different components.
It is possible to use with `<button>` or `<a>` elements

## First type button

```html_example
<button type="submit" class="b-button">
	Sign in
</button>
```

## First type disabled button

```html_example
<button type="submit" class="b-button m-disabled">
	Sign in
</button>
```

## First type, full width button

```html_example
<button type="submit" class="b-button m-width_full">
	Sign in
</button>
```

## Second type button

```html_example
<button type="submit" class="b-button m-outline">
	Sign in
</button>
```

## Second type disabled button

```html_example
<button type="submit" class="b-button m-outline m-disabled">
	Sign in
</button>
```

## Second type, full width button

```html_example
<button type="submit" class="b-button m-outline m-width_full">
	Sign in
</button>
```

## Link button

```html_example
<button type="submit" class="b-button m-link">
	Sign in
</button>
```

## Medium height Link button

```html_example
<button type="submit" class="b-button m-link m-medium">
	Sign in
</button>
```

## Small height Link button

```html_example
<button type="submit" class="b-button m-link m-small">
	Sign in
</button>
```

*/
/*md

# g-button_iconed

Designed to provide same styles of buttons that contain only icon (without any text)
across different components without explicit CSS class.

It is used for header menubar icons, hamburger menu items and dialog close button.

```scss
.b-dialog {
	// ...
	&-close {
		@include g-button_iconed;
	}
}
```
*/
/*md

# g-radio

The component is generally used for choosing item which includes in the radio group.

## Usage

Only one g-radio in a given group can be selected at the same time.

If user selects one option in the list that other options come to unselected.

`g-radio` has states: unchecked, checked, hover, disabled and invalid.

## Unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-2" class="b-radio-input"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-2">Some text</label>
</div>
```

## Checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-3" class="b-radio-input" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-3">Some text</label>
</div>
```

## Disabled unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-4" class="b-radio-input" disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-4">Some text</label>
</div>
```

## Disabled checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-5" class="b-radio-input" checked disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-5">Some text</label>
</div>
```

## Invalid unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-6" class="b-radio-input m-invalid"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-6">Some text</label>
</div>
```

## Invalid checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-7" class="b-radio-input m-invalid" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-7">Some text</label>
</div>
```

## Customization by SCSS

Radio button styles that used in several component.

Designed to use same style of radio in different components
ex: b-radio, b-payment_option, b-shipping_option, b-stores etc.

It provide styles only for icon element based on input node.

```scss_example
.b-option_switch {
	// ...
	&-input {
		@include g-radio(_input);
	}

	&-icon {
		@include g-radio(_icon);

		.b-option_switch-input:active + & {
			@include g-radio(_icon, m-active);
		}

		.b-option_switch-input:hover + & {
			@include g-radio(_icon, m-hover);
		}

		.b-option_switch-input:checked + & {
			@include g-radio(_icon, m-checked);
		}

		.b-option_switch-input[disabled] + & {
			@include g-radio(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-checkbox

This component allows user to choose between two mutually exclusive state (checked or unchecked).

## Usage

A `g-checkbox` is used for select or unselect action items.

It is always tied to `<label>` that describes two opposite states.

The component usually points to choose settings or preferences.

`g-checkbox` has states (unchecked, checked, hover, disabled and invalid).

## Unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-1" />
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-1">Some text</label>
</div>
```

## Checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-2" checked/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-2">Some text</label>
</div>
```

## Disabled unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-3" disabled/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-3">Some text</label>
</div>
```

## Disabled checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-4" checked disabled/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-4">Some text</label>
</div>
```

## Invalid unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-5"/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-5">Some text</label>
</div>
```

## Invalid checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-6" checked/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-6">Some text</label>
</div>

```

## Customization by SCSS

Checkbox styles that used in several component.

Designed to use same style of checkbox in different components without additional CSS class.
ex: `b-checkbox`, `b-refinement_checkbox`, `b-account_preference` etc.

It provide styles only for icon element based on SVG.

```scss
.b-refinement_checkbox {
	// ...
	&-icon {
		@include g-checkbox(_icon);

		.b-refinement_checkbox:active & {
			@include g-checkbox(_icon, m-active);
		}

		.b-refinement_checkbox.m-checked & {
			@include g-checkbox(_icon, m-checked);
		}

		.b-refinement_checkbox.m-disabled & {
			@include g-checkbox(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-spinner

Global spinner component applied to different blocks that fetch data.

Designed to use same style of spinner in different components and on particular breakpoints.
Ex: b-minicart_popup, b-suggestions, b-plp_grid, b-product_details, b-cart etc.

```scss
.b-product_gallery {
	&.m-loading_long::before {
		@include g-spinner();
	}
	// ...
}
```
*/
/*md

# g-link

Designed to provide same styles of text-type links across different components.

## Usage

```scss
.component-link {
	@include g-link;
}

.component-link {
	@include g-link();
}
```

*/
/*md

# g-link_hamburger

Hamburger menu generic link that used in several component.

Designed to use same style of hamburger link in different components
ex: menu, account link, language switcher etc.

```scss
.b-menu {
	// ...
	&-item {
		@include media(sm) {
			@include g-link_hamburger;
		}
	}
}
```
*/
/*md

# g-icon_gradient

Designed to provide same styles of icon gradient across different components without
explicit CSS class.

```scss
.b-user_greeting {
	// ...
	&-icon {
		@include g-icon_gradient;
	}
}
```
*/
/*md

# g-image_container

This is global component designed to hold image in place and preventing from layout bouncing during page load.

It based on `padding-bottom` trick. `padding-bottom` and `padding-top` relative units are based
on parent element `width`. So if you had an element that is 500px wide, and padding-top of 100%,
the padding-top would be 500px. [More info](https://css-tricks.com/aspect-ratio-boxes/)

```scss
.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}

.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}
```

You could change aspect ration in mixin:

```scss
@include g-image_container(_container, 100%);   // 1:1
@include g-image_container(_container, 150%);   // 2:3
@include g-image_container(_container, 133%);   // 3:4
@include g-image_container(_container, 125%);   // 5:4
@include g-image_container(_container, 75%);    // 4:3
@include g-image_container(_container, 66.6%);  // 3:2
@include g-image_container(_container, 56.25%); // 16:9
```

But it is preferable to define only one aspect ration through all images and not change it.

*/
/*md

# g-snap_scroll

Snap scroll functionality applied to different cases.

Designed to use same snap scroll functionality in different components and on particular breakpoints.
Ex: b-carousel, b-product_gallery, .b-product_slider etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include g-snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/
/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

Designed to use same style of backdrop and logic in different components and on particular breakpoints.
Ex: b-dialog, b-menu_panel, b-refinements_panel, b-minicart_panel etc.

```scss
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/
/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
as it wrapped into main container.

It could be used not only for standalone blocks, but also for page layouts.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/
/*md

# g-section_holder_header

Since header is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold header of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-header-inner {
	background: green;

	&-inner {
		@include g-section_holder_header;
	}
}
```
*/
/*md

# g-heading_*

Some basic simple typography applied to different UI components.

This covers only very basic cases and could be extended.

```scss
.b-cart_empty {
	// ...

	&-title {
		@include g-heading_1;

		margin-bottom: rh(8);
	}
}
```
*/
/*md

# g-accordion

Global accordion component

## Attributes

```
data-allow-toggle="true" - Flag that allow or dissallow toggle
data-open-first="true" - Flag that open first item
data-allow-multiple="true" - Flag that allow or dissallow multiple open items
```

## Simple accordion example

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="false"
    data-open-first="true"
    data-allow-multiple="false"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Accordion with multiple open items

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="true"
    data-open-first="false"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Init accordion on sm, md & lg devices

### Attributes

```
.b-accordion
	data-widget="accordion" - init for all viewports
	data-widget.sm="accordion" - init for sm viewports
	data-widget.sm.md="accordion" - init for sm & md viewports
	data-widget.sm.md.lg="accordion" - init for sm & md & lg viewports
.b-accordion-item
	data-widget="accordionItem" - init for all viewports
	data-widget.sm="accordionItem" - init for sm viewports
	data-widget.sm.md="accordionItem" - init for sm & md viewports
	data-widget.sm.md.lg="accordionItem" - init for sm & md & lg viewports
```

```html_example
<div
    data-id="descriptions"
    data-widget.sm.md.lg="accordion"
    data-allow-toggle="true"
    data-open-first="true"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss
.b-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(_item);
	}

	&-title {
		@include g-accordion(_control);
	}

	&-content {
		@include g-accordion(_content);

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);
	}
}
```
*/
/*md

# g-grid

Grid layout component based on CSS grid.

It is designed to easy use project defined grid into components where CSS grid is
applicable.

```scss
.b-grid {
	@include g-grid();

	.b-columns__item {
		@include media(lg-up) {
			grid-column: 2 / span 4;
			grid-row: 1 / 2;
		}

		@include media(md-down) {
			grid-column: grid-start / span 7;
		}
	}
}
```
*/
body {
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
}

.b-header_utility,
.l-header,
.b-menu_panel {
  pointer-events: all;
  visibility: visible;
}

/* stylelint-disable selector-max-universal */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/* stylelint-enable */
a {
  background-color: transparent;
}

ul,
ol,
p {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
}

figure {
  margin: 0;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

button,
input,
select,
textarea {
  color: inherit;
  font: inherit;
}

[hidden] {
  display: none !important;
}

html {
  background: #ffffff;
  color: #000001;
  direction: ltr;
  font: 14px / 1.5 "Roboto", "Century Gothic", "Apple Gothic", "AppleGothic", "URW Gothic", "Avant Garde", "Futura", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-synthesis: none;
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  box-sizing: border-box;
  margin: 0;
  min-width: 360px;
  overflow-y: scroll;
  padding: 0.01px 0 0;
}
html[dir=rtl] body {
  direction: rtl;
  letter-spacing: 0;
}

a {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: underline;
}
@media not all and (pointer: coarse) {
  a:hover {
    text-decoration: none;
  }
}

button {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
}

img {
  overflow: hidden;
}

:target::before {
  content: "";
  display: block;
  height: 65px;
  margin-top: -65px;
  visibility: hidden;
}

.b-sr_only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}

.text-decoration-none {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Georgia", "DejaVu Serif", serif;
}

.font-georgia {
  font-family: "Georgia", "DejaVu Serif", serif !important;
}

.font-roboto {
  font-family: "Roboto", "Century Gothic", "Apple Gothic", "AppleGothic", "URW Gothic", "Avant Garde", "Futura", "Arial", sans-serif !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.m-has_dialog .l-page {
  overflow: hidden;
}
.m-has_dialog .l-page-content, .m-has_dialog .l-page-footer {
  overflow-y: scroll;
}

.l-header {
  background: #ffffff;
  padding: 1px 0;
}
@media screen and (max-width: 1023px) {
  .l-header {
    position: sticky;
    top: -0.1px;
    z-index: 9;
  }
}
.m-has_dialog .l-header {
  overflow-y: scroll;
}
.l-header.m-simple {
  box-shadow: 0 1px 0 0 #f2f2f2;
  font-size: 12px;
  font-weight: 700;
}
@media screen and (max-width: 1023px) {
  .l-header.m-simple {
    position: static;
  }
}
.l-header-inner {
  margin: 0 auto;
  max-width: 1920px;
  padding-left: 32px;
  padding-right: 32px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 7px auto;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-header-inner {
    padding-left: 22px;
    padding-right: 22px;
  }
}
@media screen and (max-width: 767px) {
  .l-header-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .l-header-inner {
    height: 60px;
    margin: 12px auto 0;
  }
}
.l-header.m-simple .l-header-inner {
  margin: 32px auto;
}
@media screen and (max-width: 767px) {
  .l-header.m-simple .l-header-inner {
    flex-direction: row-reverse;
    margin: 16px auto;
  }
}
.l-header-left {
  width: 50%;
}
@media screen and (max-width: 1023px) {
  .l-header-left {
    width: auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-header.m-simple .l-header-left {
    width: 50%;
  }
}
.l-header-middle {
  display: flex;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}
@media screen and (max-width: 1023px) {
  .l-header.m-simple .l-header-middle {
    position: static;
    transform: none;
  }
}
.l-header-right {
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-header.m-simple .l-header-right {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .l-header.m-simple .l-header-right {
    display: none;
  }
}

.b-header_utility {
  background-color: #f0e9e5;
  color: #262626;
  display: flex;
}
.m-has_dialog .b-header_utility {
  overflow-y: scroll;
}
.b-header_utility-inner {
  margin: 0 auto;
  max-width: 1920px;
  padding-left: 32px;
  padding-right: 32px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 35px;
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-header_utility-inner {
    padding-left: 22px;
    padding-right: 22px;
  }
}
@media screen and (max-width: 767px) {
  .b-header_utility-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.b-header_utility-inner.pre-header {
  justify-content: center;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin: auto;
}
.b-header_utility-inner.pre-header span {
  transition: opacity 500ms;
  opacity: 0;
}
.b-header_utility-inner.pre-header span a {
  text-decoration: none;
}
.b-header_utility-inner.pre-header span.show {
  opacity: 1;
}
.b-header_utility-inner.pre-header span:not(:only-child):not(.show) {
  display: none;
}
.b-header_utility-inner.pre-header span:hover a {
  text-decoration: underline;
}
.b-header_utility-item.m-promo_slot {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin: auto;
}
@media screen and (max-width: 1023px) {
  .b-header_utility-item.m-promo_slot {
    font-size: 12px;
  }
}

.b-logo {
  color: #000001;
  display: block;
  margin: auto;
  max-width: 300px;
  min-width: 200px;
}
@media screen and (min-width: 1024px) {
  .b-header_stuck .b-logo {
    color: #000001;
  }
}
@media screen and (max-width: 767px) {
  .b-logo {
    max-width: 158px;
    min-width: 158px;
  }
}
.b-logo svg {
  max-width: 100%;
}
@media screen and (max-width: 1023px) {
  .b-logo svg {
    max-height: 40px;
  }
}
.b-logo-dot {
  fill: #d54a4a;
}

.b-logo_sticky {
  display: none;
}

.b-header_actions {
  align-items: center;
  color: #000001;
  display: flex;
}
@media screen and (max-width: 767px) {
  .b-header_actions-item.m-account, .b-header_actions-item.m-wishlist {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .b-header_actions-item.m-search, .b-header_actions-item.m-account, .b-header_actions-item.m-wishlist, .b-header_actions-item.m-storelocator, .b-header_actions-item.m-minicart {
    margin-inline-start: 8px;
  }
}
@media screen and (min-width: 1024px) {
  .b-header_actions-item.m-hamburger {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .b-header_actions-item.m-locale {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .b-header_actions-item.m-storelocator {
    display: none;
  }
}

.l-header-right .b-header_actions {
  justify-content: flex-end;
  width: 100%;
}

.header-nav-global {
  position: sticky;
  top: 0;
  z-index: 9;
}
.header-nav-global a, .header-nav-global p, .header-nav-global span {
  font-family: "Georgia", "DejaVu Serif", serif !important;
}

.b-header_button {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
}
.b-header_button:hover {
  color: #000001;
}
@media screen and (min-width: 1024px) {
  .b-header_button {
    height: 48px;
    width: 48px;
  }
}
.b-header_button.m-hamburger svg {
  height: 16px;
  width: 20px;
}

@media screen and (max-width: 1023px) {
  .b-header_login {
    color: inherit;
    cursor: pointer;
    text-underline-offset: 4px;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    display: flex;
    min-height: 48px;
    padding: 0 20px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    box-shadow: none;
    padding: 0 4px;
    text-transform: none;
  }
  @media not all and (pointer: coarse) {
    .b-header_login:hover {
      text-decoration: underline;
    }
  }
}
.b-header_login-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
}
.b-header_login-icon:hover {
  color: #000001;
}
@media screen and (min-width: 1024px) {
  .b-header_login-icon {
    height: 48px;
    width: 48px;
  }
}
@media not all and (pointer: coarse) {
  .b-header_login-icon svg {
    transform: translateY(0);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
  .b-header_login-icon:hover svg, .b-header_login-icon.m-hover svg {
    transform: translateY(-4px);
  }
}
.b-header_login-caption {
  display: flex;
}
@media screen and (min-width: 1024px) {
  .b-header_login-caption {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-header_actions-item .b-header_login-caption {
    display: none;
  }
}
.b-header_login-divider {
  margin: 0 4px;
}
.b-header_login-link {
  color: inherit;
  white-space: nowrap;
}

.b-minicart_icon-link {
  position: relative;
}
@media not all and (pointer: coarse) {
  .b-minicart_icon-link svg {
    transform: translateY(0);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
  .b-minicart_icon-link:hover svg, .b-minicart_icon-link.m-hover svg {
    transform: translateY(-4px);
  }
  .b-minicart_icon-link:hover .b-minicart_icon-qty, .b-minicart_icon-link.m-hover .b-minicart_icon-qty {
    top: calc(50% - 1px);
  }
}
.b-minicart_icon-qty {
  font-size: 10px;
  left: 50%;
  line-height: 1.2;
  position: absolute;
  text-align: center;
  top: calc(50% + 3px);
  transform: translate(-50%, -50%);
  transition: top cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}

.b-search_toggle {
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  text-align: start;
}
@media screen and (max-width: 1023px) {
  .b-search_toggle {
    font-size: 0;
  }
}
@media not all and (pointer: coarse) {
  .b-search_toggle svg {
    transform: translateY(0);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
  .b-search_toggle:hover svg, .b-search_toggle.m-hover svg {
    transform: translateY(-4px);
  }
}
.b-header_stuck .b-search_toggle {
  font-size: 0;
}
.b-search_toggle-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
  color: #000001;
  transition: color;
}
.b-search_toggle-icon:hover {
  color: #000001;
}
@media screen and (min-width: 1024px) {
  .b-search_toggle-icon {
    height: 48px;
    width: 48px;
  }
}
.b-header_stuck .b-search_toggle-icon {
  margin-inline-end: 0;
}
.b-header_stuck .b-search_toggle-icon:hover {
  background: none;
  color: #000001;
}

.b-country_selector {
  cursor: pointer;
}
@media screen and (min-width: 1024px) {
  .b-country_selector {
    align-items: center;
    display: flex;
    position: relative;
  }
}
.b-country_selector-locale {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .b-country_selector-locale.m-switcher:hover {
    background: #f2f2f2;
  }
}
.b-country_selector-item {
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .b-country_selector-switcher {
    color: inherit;
    cursor: pointer;
    text-underline-offset: 4px;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    display: flex;
    min-height: 48px;
    padding: 0 20px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    box-shadow: none;
  }
  @media not all and (pointer: coarse) {
    .b-country_selector-switcher:hover {
      text-decoration: underline;
    }
  }
}
.b-country_selector-switcher:hover {
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .b-country_selector-locale_language {
    margin: 0 12px;
  }
}
.b-country_selector-locale_icon {
  margin-inline-start: auto;
}
.b-country_selector-flyout {
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.35);
  color: #000001;
  display: none;
  left: 0;
  position: absolute;
  top: 49px;
  width: 100%;
  z-index: 4;
}
.b-country_selector-flyout[aria-hidden=false] {
  display: block;
}
.b-country_selector-title {
  color: #000001;
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.b-country_selector-description {
  line-height: 1.71;
  margin-bottom: 30px;
}
@media screen and (max-width: 1023px) {
  .b-country_selector-form {
    padding: 20px 15px;
  }
}
.b-country_selector-inner {
  background-color: #ffffff;
  bottom: 0;
  color: #000001;
  height: 100%;
  max-width: 496px;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition-property: transform;
  visibility: hidden;
  width: 80vw;
  z-index: 10;
  box-shadow: none;
  left: 0;
  margin: 0;
  max-width: 459px;
  padding: 40px;
  transform: translateX(-100%);
}
html[dir=rtl] .b-country_selector-inner {
  left: 0;
  right: initial;
  transform: translateX(-100%);
}
.b-country_selector-inner.m-activated {
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
}
.b-country_selector-inner.m-active {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.35);
  transform: translateX(0);
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  visibility: visible;
}
html[dir=rtl] .b-country_selector-inner.m-active {
  transform: translateX(0);
}
.b-country_selector-inner.m-loading_long::before {
  animation: 1s linear infinite rotate;
  border: 0.375em solid #000001;
  border-left-color: #f2f2f2;
  border-radius: 50%;
  border-top-color: #f2f2f2;
  content: "";
  display: block;
  height: 3em;
  margin: auto;
  pointer-events: none;
  position: relative;
  text-indent: -9999em;
  width: 3em;
  left: 50%;
  margin: -1em 0 0 -1em;
  position: absolute;
  top: 50%;
}
.b-country_selector-inner.m-active {
  transform: translateX(0);
}
.b-country_selector-container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  transition-property: visibility, background-color;
  visibility: hidden;
  z-index: 10;
}
.b-country_selector-container.m-opened, .b-country_selector-container.m-active {
  background-color: rgba(0, 0, 1, 0.4);
  overflow-y: scroll;
  visibility: visible;
}
.b-country_selector-container.m-opened {
  overflow: hidden;
}

.b-menu_bar {
  background-color: #ffffff;
  position: relative;
  transition: background-color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  z-index: 3;
}
.b-menu_bar-item.m-hidden {
  display: none;
}
.b-menu_bar-link {
  display: block;
  letter-spacing: 1px;
  line-height: 1.14;
  padding: 24px 32px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}
@media screen and (max-width: 1023px) {
  .b-menu_bar-link {
    color: inherit;
    cursor: pointer;
    text-underline-offset: 4px;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    display: flex;
    min-height: 48px;
    padding: 0 20px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
  }
  @media not all and (pointer: coarse) {
    .b-menu_bar-link:hover {
      text-decoration: underline;
    }
  }
  .b-menu_bar-link::after {
    background: #f2f2f2;
    content: "";
    height: 1px;
    left: 15px;
    position: absolute;
    right: 15px;
    top: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .b-menu_bar-link {
    color: inherit;
    cursor: pointer;
    text-underline-offset: 4px;
    text-decoration: none;
  }
  @media not all and (pointer: coarse) {
    .b-menu_bar-link:hover {
      text-decoration: underline;
    }
  }
  .b-menu_bar-link.m-highlight {
    color: #d54a4a;
  }
}
@media screen and (min-width: 1024px) {
  .b-menu_bar-container {
    margin: 0 auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) and (max-width: 1023px) {
  .b-menu_bar-container {
    padding-left: 22px;
    padding-right: 22px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 767px) {
  .b-menu_bar-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 1023px) {
  .b-menu_bar-inner {
    margin: 0;
  }
}
@media screen and (min-width: 1024px) {
  .b-menu_bar-inner {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 1023px) {
  .b-menu_bar-link_highlight {
    color: #d54a4a;
  }
}
@media screen and (min-width: 1024px) {
  .m-highlight .b-menu_bar-link_text::before {
    background: #d54a4a;
  }
}
@media screen and (min-width: 1024px) {
  .b-menu_bar-flyout {
    background-color: #ffffff;
    border-top: 1px solid #f2f2f2;
    box-shadow: 0 50vh 0 50vh rgba(0, 0, 1, 0.4);
    color: #000001;
    display: none;
    left: 0;
    max-height: 70vh;
    min-height: 300px;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 100%;
  }
  .b-menu_bar-flyout[aria-hidden=false] {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .b-menu_bar-flyout_inner {
    margin: 0 auto;
    max-width: 1366px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 30px;
    position: relative;
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) and (max-width: 1023px) {
  .b-menu_bar-flyout_inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 767px) {
  .b-menu_bar-flyout_inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .b-menu_bar-flyout_inner .content-asset {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .b-menu_bar-flyout_column {
    flex-basis: 16.6666666667%;
    padding-bottom: 20px;
    padding-right: 20px;
  }
}
.b-menu_bar-flyout_column.m-hidden {
  display: none;
}
@media screen and (max-width: 1023px) {
  .b-menu_bar-flyout_close {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .b-menu_bar-flyout_close {
    align-items: center;
    appearance: none;
    background: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 48px;
    justify-content: center;
    text-align: center;
    width: 48px;
    position: absolute;
    right: 8px;
    top: 14px;
  }
  .b-menu_bar-flyout_close:hover {
    color: #000001;
  }
  html[dir=rtl] .b-menu_bar-flyout_close {
    left: 8px;
    right: initial;
  }
}
@media not all and (pointer: coarse) {
  .b-menu_bar-flyout_close svg {
    transform: translateY(0);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
  .b-menu_bar-flyout_close:hover svg, .b-menu_bar-flyout_close.m-hover svg {
    transform: translateY(-4px);
  }
}
.b-menu_bar-flyout_close svg {
  height: 16px;
  width: 16px;
}
.b-menu_bar-flyout_link_wrapper {
  display: block;
  width: 100%;
}

.b-main_menu-item.m-hidden {
  display: none;
}

.b-menu_item-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
@media not all and (pointer: coarse) {
  .b-menu_item-link:hover {
    text-decoration: underline;
  }
}
@media screen and (max-width: 1023px) {
  .b-menu_item-link {
    color: inherit;
    cursor: pointer;
    text-underline-offset: 4px;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    display: flex;
    min-height: 48px;
    padding: 0 20px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
  }
  @media not all and (pointer: coarse) {
    .b-menu_item-link:hover {
      text-decoration: underline;
    }
  }
  .b-menu_item-link::after {
    background: #f2f2f2;
    content: "";
    height: 1px;
    left: 15px;
    position: absolute;
    right: 15px;
    top: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .b-menu_item-link.m-has-submenu {
    letter-spacing: 1px;
    margin-bottom: 12px;
    text-transform: uppercase;
  }
}
.b-menu_item-link.m-highlight {
  color: #d54a4a;
}
.b-menu_item-link.m-highlight:hover {
  color: #000001;
}
.b-menu_item-link.m-all_link {
  letter-spacing: 1px;
  margin-top: 5px;
  text-transform: uppercase;
}
@media screen and (min-width: 1024px) {
  .b-menu_item-link.m-all_link {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .b-menu_item-link.m-all_link_2nd {
    display: none;
  }
}
.b-menu_item-submenu {
  font-weight: 300;
  line-height: 32px;
}
.b-menu_item-link_icon {
  margin-inline-start: auto;
}
@media screen and (min-width: 1024px) {
  .b-menu_item-link_icon {
    display: none;
  }
}
.b-menu_bar-link.m-custom_menu .b-menu_item-link_icon {
  display: none;
}
.b-menu_item.m-hidden {
  display: none;
}

@media screen and (min-width: 1024px) {
  html.m-has_dialog .b-menu_panel {
    overflow-y: scroll;
  }
}
@media screen and (max-width: 1023px) {
  .b-menu_panel-wrapper {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
    transition-property: visibility, background-color;
    visibility: hidden;
    z-index: 10;
  }
  .b-menu_panel-wrapper.m-opened, .b-menu_panel-wrapper.m-active {
    background-color: rgba(0, 0, 1, 0.4);
    overflow-y: scroll;
    visibility: visible;
  }
}
@media screen and (max-width: 1023px) {
  .b-menu_panel-inner {
    background-color: #ffffff;
    bottom: 0;
    height: 100%;
    left: 0;
    max-width: 400px;
    overflow: hidden;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    transition-property: transform;
    visibility: hidden;
    width: 90vw;
    z-index: 10;
  }
  html[dir=rtl] .b-menu_panel-inner {
    left: initial;
    right: 0;
    transform: translateX(100%);
  }
  .b-menu_panel-inner.m-closed {
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  }
  .b-menu_panel-inner.m-opened {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.35);
    transform: translateX(0);
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
    visibility: visible;
  }
  html[dir=rtl] .b-menu_panel-inner.m-opened {
    transform: translateX(0);
  }
  .b-menu_panel-inner.m-no_transition {
    transition: none !important;
  }
}
.b-menu_panel-head {
  display: none;
}
@media screen and (max-width: 1023px) {
  .b-menu_panel-head {
    align-items: center;
    background-color: #f2f2f2;
    display: flex;
    justify-content: space-between;
    min-height: 48px;
  }
}
.b-menu_panel-title {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.14;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
.b-menu_panel-back {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  text-align: center;
  width: 48px;
}
.b-menu_panel-back:hover {
  color: #000001;
}
.b-menu_panel-footer {
  margin-top: 8px;
}
@media screen and (min-width: 1024px) {
  .b-menu_panel-footer {
    display: none;
  }
}
.b-menu_panel-close {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  text-align: center;
  width: 56px;
  margin-inline-start: auto;
  padding: 16px 20px;
}
.b-menu_panel-close:hover {
  color: #000001;
}
.b-menu_panel-close svg {
  height: 16px;
  width: 16px;
}

@media screen and (min-width: 1024px) {
  .b-menu_subpanel-container.m-level_2, .b-menu_subpanel-container.m-level_3 {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .b-menu_subpanel {
    display: flex;
    height: 100%;
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  }
  .b-menu_subpanel.m-active_level_1 {
    transform: translateX(0);
  }
  .b-menu_subpanel.m-active_level_2 {
    transform: translateX(-100%);
  }
  html[dir=rtl] .b-menu_subpanel.m-active_level_2 {
    transform: translateX(100%);
  }
  .b-menu_subpanel.m-active_level_3 {
    transform: translateX(-200%);
  }
  html[dir=rtl] .b-menu_subpanel.m-active_level_3 {
    transform: translateX(200%);
  }
  .b-menu_subpanel-container {
    min-width: 100%;
    overflow-y: auto;
    padding-bottom: 88px;
  }
  .b-menu_subpanel-container.m-level_1 .b-menu_subpanel-content.m-level_2_content, .b-menu_subpanel-container.m-level_2 .b-menu_subpanel-content.m-level_3_content {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .b-flyout_promo {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .b-flyout_promo {
    display: block;
    padding-bottom: 20px;
    position: relative;
    width: 408px;
  }
}
.b-flyout_promo.m-fullwidth {
  margin: 0 auto;
  max-width: 836px;
  width: 100%;
}
.b-flyout_promo-picture {
  background: #f8f5f5;
  display: block;
  overflow: hidden;
  padding-bottom: 75%;
  position: relative;
  width: 100%;
}
.m-fullwidth .b-flyout_promo-picture {
  padding-bottom: 39.95%;
}
.b-flyout_promo-img {
  bottom: 0;
  color: #f8f5f5;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-flyout_promo-title {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.b-flyout_promo-actions {
  margin-top: 10px;
}
.b-flyout_promo-actions a {
  padding-left: 40px;
  padding-right: 40px;
}
.b-flyout_promo-caption {
  bottom: 0;
  left: 0;
  padding: 30px 30px 50px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
}

.b-skip_to {
  background-color: #f6f8fe;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  clip: rect(1px, 1px, 1px, 1px);
  color: #000001;
  display: block;
  left: 0;
  margin: 0 auto;
  max-width: 320px;
  opacity: 0;
  overflow: hidden;
  padding: 12px 48px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 8px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: opacity, clip;
  z-index: 13;
}
.b-skip_to:focus {
  clip: auto;
  opacity: 1;
}

.b-header_message {
  font-size: 15px;
  font-weight: 500;
  padding: 8px 0;
  text-align: center;
  visibility: visible;
}
.b-header_message.m-error {
  background-color: #d54a4a;
  color: #ffffff;
}
.b-header_message-inner {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 960px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-header_message-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-header_message-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-header_message a {
  color: inherit;
  text-decoration: underline;
}

.b-find_store {
  height: 48px;
  width: 48px;
}
@media screen and (min-width: 768px) {
  .b-find_store {
    align-items: center;
    color: #000001;
    display: inline-flex;
    justify-content: center;
  }
}
@media not all and (pointer: coarse) {
  .b-find_store svg {
    transform: translateY(0);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
  .b-find_store:hover svg, .b-find_store.m-hover svg {
    transform: translateY(-4px);
  }
}
.b-find_store svg {
  height: 16px;
  width: 12px;
}
.b-header_actions-item .b-find_store svg {
  color: #000001;
}
.b-menu_panel-footer .b-find_store {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: 48px;
  padding: 0 20px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  border: none;
  box-shadow: none;
  justify-content: flex-start;
  padding: 0 4px;
  text-transform: none;
}
@media not all and (pointer: coarse) {
  .b-menu_panel-footer .b-find_store:hover {
    text-decoration: underline;
  }
}
.b-menu_panel-footer .b-find_store-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
}
.b-menu_panel-footer .b-find_store-icon:hover {
  color: #000001;
}

.b-switch_country .b-dialog-header {
  justify-content: center;
}
.b-switch_country .b-dialog-header .b-dialog-title {
  text-align: center;
}
.b-switch_country .b-dialog-body p {
  text-align: center;
}
.b-switch_country-inner .b-dialog-window.m-active {
  min-height: 25rem;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.b-badges {
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  left: 15px;
  max-width: calc(100% - 156px);
  position: absolute;
  top: 15px;
  z-index: 1;
}
.b-badges-item {
  background-color: #ebddd3;
  border-radius: 2px;
  color: #000001;
  font-size: 12px;
  line-height: 1;
  padding: 5px 10px;
  text-transform: uppercase;
  word-break: break-word;
}
.b-badges-item + .b-badges-item {
  margin-top: 4px;
}
.b-product_tile .b-badges, .b-carousel .b-badges {
  left: auto;
  max-width: calc(100% - 68px);
  right: 10px;
  top: 10px;
}
.b-wishlist_tile .b-badges {
  left: 10px;
  max-width: calc(100% - 68px);
  top: 10px;
}

/*md

# b-carousel (based on scroll)

## Carousel examples

### 1. Hero carousel

```html_example
<div data-widget="carousel" class="b-carousel m-hero">
    <button class="b-carousel-ctrl m-prev" tabindex="-1" aria-hidden="true" title="Previous" data-ref="elemPrevButton" data-event-click="scrollToPrevPage">
        <svg width="40" height="40" viewBox="2 1 40 40" aria-hidden="true" focusable="false">
            <path d="m26.541 8.3536-12.728 12.728 12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
        </svg>
    </button>
    <button class="b-carousel-ctrl m-next" tabindex="-1" aria-hidden="true" title="Next" data-ref="elemNextButton" data-event-click="scrollToNextPage">
        <svg width="40" height="40" viewBox="-1 1 40 40" aria-hidden="true" focusable="false">
            <path d="m13.459 8.3536 12.728 12.728-12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
        </svg>
    </button>
    <div class="b-carousel-track" data-ref="elemCarouselTrack" data-event-scroll.passive="onScroll" data-event-touchstart="onScroll" data-event-mousedown.prevent="onMouseDown" data-event-mouseup="onMouseUp">
        <div class="b-carousel-item">
            <figure class="b-promo_box m-full_bleed m-caption_offcenter">
                <picture class="b-promo_box-picture">
                    <img src="../images/guide/examples/hero-carousel-banner-1.jpg?$staticlink$" alt="Girl stand on the beach." loading="eager" width="1600" height="800">
                </picture>
                <figcaption class="b-promo_box-inner">
                    <div class="b-promo_box-caption b-promo_caption">
                        <h2 class="b-promo_caption-title">Carousel first slide</h2>
                        <p class="b-promo_caption-subtitle">Everyone's fallen for boilerplate so we added to her games repertoire.</p>
                        <div class="b-promo_caption-actions">
                            <a class="b-button" href="#" aria-label="Shop new season at boilerplate">Shop New Season</a>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </div>
        <div class="b-carousel-item">
            <figure class="b-promo_box m-full_bleed m-caption_offcenter">
                <picture class="b-promo_box-picture">
                    <img src="../images/guide/examples/hero-carousel-banner-2.jpg?$staticlink$" alt="Girl with khaki panama." loading="lazy" width="1600" height="800">
                </picture>
                <figcaption class="b-promo_box-inner">
                    <div class="b-promo_box-caption b-promo_caption">
                        <h2 class="b-promo_caption-title">Carousel second slide</h2>
                        <p class="b-promo_caption-subtitle">New playful styles inspired by darts and that staple of a British pub - the fruit machine.</p>
                        <div class="b-promo_caption-actions">
                            <a class="b-button" href="#" aria-label="Shop new Collection at boilerplate">Shop New Collection</a>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </div>
        <div class="b-carousel-item">
            <figure class="b-promo_box m-full_bleed m-caption_offcenter">
                <picture class="b-promo_box-picture">
                    <img src="../images/guide/examples/hero-carousel-banner-3.jpg?$staticlink$" alt="Girl stand in cloak." loading="lazy" width="1600" height="800">
                </picture>
                <figcaption class="b-promo_box-inner">
                    <div class="b-promo_box-caption b-promo_caption">
                        <h2 class="b-promo_caption-title">Carousel third slide</h2>
                        <div class="b-promo_caption-actions">
                            <a class="b-button" href="#" aria-label="Shop new Season at boilerplate">Shop New Season</a>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </div>
    </div>
    <div aria-hidden="true" class="b-carousel-pagination" data-ref="pagination">
        <button class="b-carousel-page" data-page="0" tabindex="-1" data-event-click.prevent="handlePaginationClick"></button>
        <button class="b-carousel-page" data-page="1" tabindex="-1" data-event-click.prevent="handlePaginationClick"></button>
        <button class="b-carousel-page" data-page="2" tabindex="-1" data-event-click.prevent="handlePaginationClick"></button>
    </div>
</div>
```

### 2. Products carousel

```html_example
<section data-widget="carousel" class="b-carousel m-products">
    <h2 class="b-carousel-title">Look what's new</h2>
    <button class="b-carousel-ctrl m-prev" tabindex="-1" aria-hidden="true" title="Previous" data-ref="elemPrevButton" data-event-click="scrollToPrevPage">
        <svg width="40" height="40" viewBox="2 1 40 40" aria-hidden="true" focusable="false">
            <path d="m26.541 8.3536-12.728 12.728 12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
        </svg>
    </button>
    <button class="b-carousel-ctrl m-next" tabindex="-1" aria-hidden="true" title="Next" data-ref="elemNextButton" data-event-click="scrollToNextPage">
        <svg width="40" height="40" viewBox="-1 1 40 40" aria-hidden="true" focusable="false">
            <path d="m13.459 8.3536 12.728 12.728-12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
        </svg>
    </button>
    <div class="b-carousel-track" data-ref="elemCarouselTrack" data-event-scroll.passive="onScroll" data-event-touchstart="onScroll" data-event-mousedown.prevent="onMouseDown" data-event-mouseup="onMouseUp">
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
    </div>
</section>
<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <symbol id="star">
        <path d="m13.344 15.662-5.0953-2.6691-5.0873 2.6842 0.96393-5.6707-4.1249-4.0089 5.691-0.83558 2.538-5.1618 2.5533 5.1543 5.6935 0.81868-4.113 4.0211z"></path>
    </symbol>
</svg>
```
*/
.b-carousel {
  position: relative;
}
.b-carousel-title {
  font-size: 27px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .b-carousel-title {
    font-size: 24px;
    margin-bottom: 15px;
  }
}
.b-carousel-track {
  display: flex;
  overflow: hidden;
  overflow-behavior: contain;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  -ms-scroll-chaining: none;
  scrollbar-width: none;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
}
.b-carousel-track::-webkit-scrollbar {
  display: none;
}
.b-carousel-track.m-mousemove_navigation {
  scroll-snap-type: unset;
}
.b-carousel-track.m-grabbing {
  cursor: grab;
  scroll-behavior: auto;
  user-select: none;
}
.b-carousel-track.m-grabbing::before {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
.b-carousel-item {
  scroll-snap-align: start;
}
.b-carousel-ctrl {
  appearance: none;
  background-color: rgba(255, 255, 255, 0.26);
  border: none;
  color: #000001;
  cursor: pointer;
  display: none;
  height: 40px;
  margin-top: -24px;
  position: absolute;
  text-align: center;
  top: 50%;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, opacity;
  user-select: none;
  width: 40px;
  z-index: 2;
}
@media not all and (pointer: coarse) {
  .b-carousel-ctrl:hover {
    background-color: #ffffff;
  }
}
.b-carousel.m-inited .b-carousel-ctrl {
  display: block;
}
@media screen and (max-width: 767px) {
  .b-carousel.m-inited .b-carousel-ctrl {
    display: none;
  }
}
.b-carousel-ctrl[disabled], .b-carousel.m-no_scroll .b-carousel-ctrl {
  opacity: 0;
  z-index: -1;
}
.b-carousel-ctrl.m-prev {
  left: 0;
}
.b-carousel-ctrl.m-next {
  right: 0;
}
.b-carousel-ctrl path {
  stroke-width: 1;
}
.b-carousel-pagination {
  bottom: 16px;
  display: none;
  justify-content: center;
  left: 9px;
  position: absolute;
  right: 9px;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .b-carousel-pagination {
    justify-content: flex-start;
  }
}
.b-carousel.m-inited .b-carousel-pagination {
  display: flex;
}
.b-carousel.m-no_scroll .b-carousel-pagination {
  display: none;
}
.b-carousel-page {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  height: 20px;
  line-height: 20px;
  position: relative;
  text-align: center;
  width: 20px;
}
.b-carousel-page::before {
  background-color: #000001;
  border: 1px solid #ffffff;
  border-radius: 8px;
  content: "";
  display: inline-block;
  height: 8px;
  left: 50%;
  margin-inline-start: -4px;
  margin-top: -4px;
  position: absolute;
  top: 50%;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: width, height, margin;
  width: 8px;
}
.b-carousel-page.m-current::before {
  background-color: #000001;
  content: "";
  height: 14px;
  margin-inline-start: -7px;
  margin-top: -7px;
  width: 14px;
}

.b-carousel.m-products,
.b-carousel.m-recently_viewed_products {
  margin: 40px 0;
}
@media screen and (max-width: 767px) {
  .b-carousel.m-products,
.b-carousel.m-recently_viewed_products {
    margin: 30px 0;
  }
}
.b-carousel.m-products .b-carousel-ctrl.m-prev,
.b-carousel.m-recently_viewed_products .b-carousel-ctrl.m-prev {
  left: -40px;
}
.b-carousel.m-products .b-carousel-ctrl.m-next,
.b-carousel.m-recently_viewed_products .b-carousel-ctrl.m-next {
  right: -40px;
}
.b-carousel.m-products .b-carousel-item,
.b-carousel.m-recently_viewed_products .b-carousel-item {
  margin-inline-end: 20px;
  max-width: 15.956%;
  min-width: 15.956%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-carousel.m-products .b-carousel-item,
.b-carousel.m-recently_viewed_products .b-carousel-item {
    max-width: 30%;
    min-width: 30%;
  }
}
@media screen and (max-width: 767px) {
  .b-carousel.m-products .b-carousel-item,
.b-carousel.m-recently_viewed_products .b-carousel-item {
    max-width: 58.56%;
    min-width: 58.56%;
  }
}

.b-carousel.m-recently_viewed_products {
  margin: 40px 0;
}
.b-carousel.m-recently_viewed_products .b-carousel-ctrl.m-prev {
  left: -40px;
}
.b-carousel.m-recently_viewed_products .b-carousel-ctrl.m-next {
  right: -40px;
}
.b-carousel.m-recently_viewed_products .b-carousel-item {
  margin-inline-end: 20px;
  max-width: 15.956%;
  min-width: 15.956%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-carousel.m-recently_viewed_products .b-carousel-item {
    max-width: 25%;
    min-width: 25%;
  }
}
@media screen and (max-width: 767px) {
  .b-carousel.m-recently_viewed_products .b-carousel-item {
    max-width: 58.56%;
    min-width: 58.56%;
  }
}

.b-carousel.m-hero {
  margin: 0 auto;
  max-width: 1920px;
}
.b-carousel.m-hero .b-carousel-ctrl.m-prev {
  left: 40px;
}
.b-carousel.m-hero .b-carousel-ctrl.m-next {
  right: 40px;
}
.b-carousel.m-hero .b-carousel-item {
  max-width: 100%;
  min-width: 100%;
}

.b-hero_carousel {
  margin: 0 auto;
  max-width: 1920px;
  overflow: hidden;
  position: relative;
}
.b-hero_carousel-track {
  display: flex;
  overflow: hidden;
}
.b-hero_carousel-track.m-grabbing {
  cursor: grab;
  user-select: none;
}
.b-hero_carousel-track.m-grabbing::before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
.b-hero_carousel-item {
  height: 100%;
  left: 0;
  min-width: 100%;
  top: 0;
  width: 100%;
  will-change: transform;
  z-index: 1;
}
.b-hero_carousel-item:not(:first-child) {
  visibility: hidden;
}
.b-hero_carousel.m-initialized .b-hero_carousel-item {
  overflow: hidden;
  position: absolute;
  transition: cubic-bezier(0.56, 0.03, 0.47, 0.98) 0.8s;
  transition-property: transform, visibility;
  visibility: hidden;
}
.b-hero_carousel.m-initialized .b-hero_carousel-item.m-prev {
  transform: translateX(-100%);
}
.b-hero_carousel.m-initialized .b-hero_carousel-item.m-next {
  transform: translateX(100%);
}
.b-hero_carousel.m-initialized .b-hero_carousel-item.m-current {
  position: static;
  transform: translateX(0);
  visibility: visible;
}
.b-hero_carousel-ctrl {
  appearance: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: none;
  height: 48px;
  margin-top: -24px;
  position: absolute;
  text-align: center;
  top: 50%;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, opacity;
  user-select: none;
  width: 48px;
  z-index: 2;
}
@media not all and (pointer: coarse) {
  .b-hero_carousel-ctrl svg {
    transform: translateY(0);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
  .b-hero_carousel-ctrl:hover svg, .b-hero_carousel-ctrl.m-hover svg {
    transform: translateY(-4px);
  }
}
.b-hero_carousel.m-initialized .b-hero_carousel-ctrl {
  display: block;
}
@media screen and (max-width: 767px) {
  .b-hero_carousel.m-initialized .b-hero_carousel-ctrl {
    display: none;
  }
}
.b-hero_carousel-ctrl.m-prev {
  left: 16px;
}
.b-hero_carousel-ctrl.m-prev.black-arrow {
  color: #000001;
}
.b-hero_carousel-ctrl.m-next {
  right: 16px;
}
.b-hero_carousel-ctrl.m-next.black-arrow {
  color: #000001;
}
.b-hero_carousel-pagination {
  bottom: 12px;
  display: flex;
  justify-content: center;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  visibility: hidden;
  z-index: 2;
}
.b-hero_carousel-pagination.m-initialized {
  opacity: 1;
  visibility: visible;
}
.b-hero_carousel-pagination_dots {
  display: flex;
  margin: 0 4px 0 0;
}
.b-hero_carousel-pagination_content {
  align-items: center;
  display: flex;
}
.b-hero_carousel-pagination_content.black-dots .b-hero_carousel-pagination_dot {
  fill: #000001;
}
.b-hero_carousel-pagination_content.black-dots .b-hero_carousel-pagination_svg_dot_outline {
  stroke: #000001;
}
.b-hero_carousel-pagination_content.white-dots .b-hero_carousel-pagination_dot {
  fill: #ffffff;
}
.b-hero_carousel-pagination_content.white-dots .b-hero_carousel-pagination_svg_dot_outline {
  stroke: #ffffff;
}
.b-hero_carousel-pagination_dot {
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  fill: #ffffff;
  height: 17px;
  line-height: 1;
  margin-inline-end: 6px;
  padding: 0;
  position: relative;
  width: 17px;
}
@media not all and (pointer: coarse) {
  .b-hero_carousel-pagination_dot svg {
    transform: translateY(0);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
  .b-hero_carousel-pagination_dot:not(.m-current):hover svg, .b-hero_carousel-pagination_dot:not(.m-current):hover.m-hover svg {
    transform: translateY(-4px);
  }
}
.b-hero_carousel-pagination_dot:last-child {
  margin-inline-end: 0;
}
.b-hero_carousel-pagination_dot[aria-disabled=true] {
  cursor: initial;
}
.m-current .b-hero_carousel-pagination_svg_dot {
  display: none;
}
.b-hero_carousel-pagination_svg_dot_outline {
  display: none;
  stroke: #ffffff;
  stroke-width: 1;
  transition: 0.4s ease;
  transition-property: stroke-width;
}
.m-current .b-hero_carousel-pagination_svg_dot_outline {
  display: block;
}
.b-hero_carousel-autoplay {
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  margin-inline-end: 4px;
  padding: 0;
}
.b-hero_carousel-autoplay.m-initialized {
  display: block;
}
.b-hero_carousel-autoplay.m-animated {
  animation: hero-carousel-progress linear;
}
.b-hero_carousel-autoplay_svg {
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 1;
}
.b-hero_carousel-autoplay_progress {
  stroke: #ffffff;
  stroke-width: 3;
}
.b-hero_carousel-autoplay.m-animated .b-hero_carousel-autoplay_progress {
  stroke: #c5c5c5;
}
.b-hero_carousel-autoplay_progress_back {
  stroke: #ffffff;
  stroke-width: 3;
}
.b-hero_carousel-autoplay_play, .b-hero_carousel-autoplay_pause {
  display: block;
  fill: #ffffff;
  opacity: 1;
  stroke: #ffffff;
  transition: opacity ease 0.2s;
}
@media not all and (pointer: coarse) {
  .b-hero_carousel-autoplay:hover .b-hero_carousel-autoplay_play, .b-hero_carousel-autoplay:hover .b-hero_carousel-autoplay_pause {
    fill: #dbcbc1;
    stroke: #dbcbc1;
  }
}
.b-hero_carousel-autoplay[aria-pressed=false] .b-hero_carousel-autoplay_play {
  opacity: 0;
}
.b-hero_carousel-autoplay_pause {
  stroke-width: 3;
}
.b-hero_carousel-autoplay[aria-pressed=true] .b-hero_carousel-autoplay_pause {
  opacity: 0;
}
.b-hero_carousel-autoplay.black-dots .b-hero_carousel-autoplay_progress {
  stroke: #000001;
}
.b-hero_carousel-autoplay.m-animated .b-hero_carousel-autoplay.black-dots .b-hero_carousel-autoplay_progress {
  stroke: #757575;
}
.b-hero_carousel-autoplay.black-dots .b-hero_carousel-autoplay_progress_back {
  stroke: #757575;
}
.b-hero_carousel-autoplay.black-dots .b-hero_carousel-autoplay_play, .b-hero_carousel-autoplay.black-dots .b-hero_carousel-autoplay_pause {
  fill: #000001;
  stroke: #000001;
}
@media not all and (pointer: coarse) {
  .b-hero_carousel-autoplay:hover .b-hero_carousel-autoplay.black-dots .b-hero_carousel-autoplay_play, .b-hero_carousel-autoplay:hover .b-hero_carousel-autoplay.black-dots .b-hero_carousel-autoplay_pause {
    fill: #dbcbc1;
    stroke: #dbcbc1;
  }
}

.b-carousel_and_text_wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 767px) {
  .b-carousel_and_text_wrapper {
    display: block;
  }
}
.b-carousel_and_text_wrapper.ratio_16-9 .b-hero_carousel-item {
  aspect-ratio: 16/9;
}
.b-carousel_and_text_wrapper.ratio_16-9 .b-promo_info_box {
  aspect-ratio: 16/9;
}
@media screen and (max-width: 767px) {
  .b-carousel_and_text_wrapper.ratio_16-9 .b-promo_info_box {
    aspect-ratio: unset;
  }
}
.b-carousel_and_text_wrapper.ratio_16-9 .b-video {
  aspect-ratio: 16/9;
}
@media screen and (max-width: 767px) {
  .b-carousel_and_text_wrapper.ratio_16-9 .b-video {
    aspect-ratio: inherit;
  }
}
.b-carousel_and_text_wrapper.ratio_16-9 .b-video .b-video-controls-wrapper {
  bottom: 30px;
}
@media screen and (max-width: 767px) {
  .b-carousel_and_text_wrapper.ratio_16-9 .b-video .b-video-controls-wrapper {
    top: 172px;
    bottom: unset;
  }
}
@media screen and (max-width: 767px) {
  .b-carousel_and_text_wrapper.ratio_16-9 .b-promo_box.m-full_bleed .b-promo_box-picture {
    padding-bottom: 56.25%;
  }
}
.b-carousel_and_text_wrapper.ratio_16-9 figure picture img {
  object-fit: contain;
}
.b-carousel_and_text_wrapper.ratio_4-5 .b-hero_carousel-item {
  aspect-ratio: 4/5;
}
.b-carousel_and_text_wrapper.ratio_4-5 .b-promo_info_box {
  aspect-ratio: 4/5;
}
@media screen and (max-width: 767px) {
  .b-carousel_and_text_wrapper.ratio_4-5 .b-promo_info_box {
    aspect-ratio: unset;
  }
}
.b-carousel_and_text_wrapper.ratio_4-5 .b-video {
  aspect-ratio: 4/5;
}
@media screen and (max-width: 767px) {
  .b-carousel_and_text_wrapper.ratio_4-5 .b-video {
    aspect-ratio: inherit;
  }
}
.b-carousel_and_text_wrapper.ratio_4-5 .b-video .b-video-controls-wrapper {
  bottom: 25px;
}
.b-carousel_and_text_wrapper.ratio_1-1 .b-hero_carousel-item {
  aspect-ratio: 1/1;
}
.b-carousel_and_text_wrapper.ratio_1-1 .b-promo_info_box {
  aspect-ratio: 1/1;
}
@media screen and (max-width: 767px) {
  .b-carousel_and_text_wrapper.ratio_1-1 .b-promo_info_box {
    aspect-ratio: unset;
  }
}
.b-carousel_and_text_wrapper.ratio_1-1 .b-video {
  aspect-ratio: 1/1;
}
@media screen and (max-width: 767px) {
  .b-carousel_and_text_wrapper.ratio_1-1 .b-video {
    aspect-ratio: inherit;
  }
}
.b-carousel_and_text_wrapper.ratio_1-1 .b-video .b-video-controls-wrapper {
  bottom: 20px;
}
.b-carousel_and_text_wrapper .b-hero_carousel {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .b-carousel_and_text_wrapper .b-hero_carousel {
    width: 100%;
  }
}
.b-carousel_and_text_wrapper .b-hero_carousel.switch {
  order: 2;
}
.b-carousel_and_text_wrapper .second_el_wrapper {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .b-carousel_and_text_wrapper .second_el_wrapper {
    width: 100%;
  }
}

.b-carousel_book_scroll-widget {
  display: flex;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 767px) {
  .b-carousel_book_scroll-widget {
    display: flex;
    flex-direction: column;
  }
}
.b-carousel_book_scroll-widget.ratio_4-5 .b-hero_carousel-item {
  aspect-ratio: 4/5;
}
.b-carousel_book_scroll-widget.ratio_4-5 .b-promo_info_box {
  aspect-ratio: 4/5;
}
@media screen and (max-width: 767px) {
  .b-carousel_book_scroll-widget.ratio_4-5 .b-promo_info_box {
    aspect-ratio: unset;
  }
}
.b-carousel_book_scroll-widget.ratio_4-5 .b-video.video-play-full {
  aspect-ratio: 4/5;
}
.b-carousel_book_scroll-widget.ratio_4-5 .carousel-book .b-promo_box {
  width: 60%;
  height: 60%;
}
.b-carousel_book_scroll-widget.ratio_4-5 .carousel-book a {
  width: 60%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.b-carousel_book_scroll-widget.ratio_4-5 .carousel-book a .b-promo_box {
  width: 100%;
  height: 100%;
}
.b-carousel_book_scroll-widget.ratio_4-5 .b-promo_caption-title {
  top: 6%;
}
@media screen and (max-width: 1023px) {
  .b-carousel_book_scroll-widget.ratio_4-5 .b-promo_caption-title {
    top: 4%;
  }
}
.b-carousel_book_scroll-widget.ratio_4-5 .b-promo_caption-subtitle {
  top: 83%;
}
.b-carousel_book_scroll-widget.ratio_1-1 .b-hero_carousel-item {
  aspect-ratio: 1/1;
}
.b-carousel_book_scroll-widget.ratio_1-1 .b-promo_info_box {
  aspect-ratio: 1/1;
}
@media screen and (max-width: 767px) {
  .b-carousel_book_scroll-widget.ratio_1-1 .b-promo_info_box {
    aspect-ratio: unset;
  }
}
.b-carousel_book_scroll-widget.ratio_1-1 .b-video.video-play-full {
  aspect-ratio: 1/1;
}
.b-carousel_book_scroll-widget.ratio_1-1 .carousel-book .b-promo_box {
  width: 48%;
  height: 48%;
}
.b-carousel_book_scroll-widget.ratio_1-1 .carousel-book a {
  width: 48%;
  height: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.b-carousel_book_scroll-widget.ratio_1-1 .carousel-book a .b-promo_box {
  width: 100%;
  height: 100%;
}
.b-carousel_book_scroll-widget.ratio_1-1 .b-promo_caption-title {
  top: 10%;
}
@media screen and (max-width: 1023px) {
  .b-carousel_book_scroll-widget.ratio_1-1 .b-promo_caption-title {
    top: 5%;
  }
}
.b-carousel_book_scroll-widget.ratio_1-1 .b-promo_caption-subtitle {
  top: 82%;
}
.b-carousel_book_scroll-widget .b-hero_carousel {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .b-carousel_book_scroll-widget .b-hero_carousel {
    width: 100%;
  }
}
.b-carousel_book_scroll-widget .b-hero_carousel.switch {
  order: 2;
}
.b-carousel_book_scroll-widget .b-hero_carousel .b-promo_caption-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.b-carousel_book_scroll-widget .b-hero_carousel .b-promo_caption-subtitle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .b-carousel_book_scroll-widget .b-hero_carousel .b-promo_caption-subtitle {
    display: block;
  }
}
.b-carousel_book_scroll-widget .b-hero_carousel .b-hero_carousel-item.carousel-book {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
}
.b-carousel_book_scroll-widget .b-hero_carousel[data-book-scroll-slave=true] {
  pointer-events: none !important;
}
.b-carousel_book_scroll-widget .b-hero_carousel[data-book-scroll-slave=true] a {
  pointer-events: auto !important;
}
.b-carousel_book_scroll-widget .b-hero_carousel-dsk {
  display: block;
}
@media screen and (max-width: 767px) {
  .b-carousel_book_scroll-widget .b-hero_carousel-dsk {
    display: none;
  }
}
.b-carousel_book_scroll-widget .b-hero_carousel-mb {
  display: none;
}
@media screen and (max-width: 767px) {
  .b-carousel_book_scroll-widget .b-hero_carousel-mb {
    display: block;
  }
}
.b-carousel_book_scroll-widget .second_el_wrapper .b-hero_carousel-ctrl {
  display: none;
}
.b-carousel_book_scroll-widget .second_el_wrapper .b-hero_carousel-pagination {
  display: none;
}
@media screen and (max-width: 767px) {
  .b-carousel_book_scroll-widget .second_el_wrapper .b-hero_carousel-pagination {
    display: block;
  }
}

.b-dialog {
  visibility: hidden;
}

/*md

# b-breadcrumbs

Breadcrumbs navigation is represented as a list of links.

The last item of breadcrumbs list (e.g. "Level 4" link) is hidden. It links to the current page and is only needed for accessibility purposes.

```html_example
<nav class="b-breadcrumbs" aria-label="Breadcrumbs">
    <ul class="b-breadcrumbs-list">
    	<li class="b-breadcrumbs-item">
    	    <a class="b-breadcrumbs-link" href="">Homepage</a>
    	</li>
    	<li class="b-breadcrumbs-item">
    	    <svg class="b-breadcrumbs-icon" aria-hidden="true" width="9" height="9" focusable="false">
    	        <use href="#arrow-down-small"></use>
    	    </svg>
    	    <a class="b-breadcrumbs-link" href="">Link 1</a>
    	</li>
    	<li class="b-breadcrumbs-item">
    	    <svg class="b-breadcrumbs-icon" aria-hidden="true" width="9" height="9" focusable="false">
    	        <use href="#arrow-down-small"></use>
    	    </svg>
    	    <a class="b-breadcrumbs-link" href="">Link 2</a>
    	</li>
    	<li class="b-breadcrumbs-item">
    	    <svg class="b-breadcrumbs-icon" aria-hidden="true" width="9" height="9" focusable="false">
    	       <use href="#arrow-down-small"></use>
    	    </svg>
    	    <a class="b-breadcrumbs-link" href="">Link 3</a>
    	</li>
    </ul>
</nav>

<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <symbol id="arrow-down-small" viewBox="0 0 10 6">
        <path fill="currentColor" d="M5 6c-.256 0-.512-.098-.707-.293l-4-4c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l3.305 3.305L8.305.418c.4-.383 1.03-.372 1.414.025.384.397.373 1.031-.024 1.414l-4 3.862C5.5 5.907 5.25 6 5 6"></path>
    </symbol>
</svg>
```
*/
.b-breadcrumbs {
  overflow-x: auto;
  scrollbar-width: none;
}
.b-breadcrumbs::-webkit-scrollbar {
  display: none;
}
.b-breadcrumbs-list {
  display: flex;
}
.b-breadcrumbs-item {
  margin-inline-end: 4px;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .b-breadcrumbs-item:not(:nth-last-child(2)) {
    display: flex;
    margin: 0;
    opacity: 0;
    width: 0;
    z-index: -1;
  }
}
.b-breadcrumbs-item:last-child {
  opacity: 0;
  z-index: -1;
}
.b-breadcrumbs-item:last-child:focus-within {
  display: initial;
  opacity: 1;
  width: auto;
}
.b-breadcrumbs-icon {
  color: #000001;
  transform: rotate(270deg);
}
@media screen and (max-width: 767px) {
  .b-breadcrumbs-icon {
    transform: rotate(90deg);
  }
}
html[dir=rtl] .b-breadcrumbs-icon {
  transform: rotate(90deg);
}
.b-breadcrumbs-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  color: #000001;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  text-decoration: underline;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: color;
  white-space: nowrap;
}
@media not all and (pointer: coarse) {
  .b-breadcrumbs-link:hover {
    text-decoration: underline;
  }
}
.b-breadcrumbs-link:hover {
  text-decoration: none;
}
.b-breadcrumbs-link[aria-current=page] {
  cursor: default;
  pointer-events: none;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .b-breadcrumbs-link[aria-current=page] {
    display: inline-block;
  }
}

.b-nav_aux {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
  color: #757575;
  margin-bottom: 20px;
  margin-top: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-nav_aux {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-nav_aux {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 767px) {
  .b-nav_aux {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

/*md

# b-promo_line

Promo-line is classic text promo banner. It scratched to full viewport width and
placed right under the header.

It consist from several elements, but has only one to place text into it `b-promo_line-inner`.

It could consist inline images, rich formatting text (`strong`, `em`) or links.

## Example

```html_example
<div class="b-promo_line m-header">
	<p class="b-promo_line-inner">
		Get 20% off everything with code: <strong>ILOVE20</strong> <a href="$url('Product-Show', 'pid', 'product-1')$">Buy now</a>
	</p>
</div>
```

*/
.b-promo_line {
  background-color: #f2f2f2;
  color: #000001;
  font-size: 16px;
  font-weight: 500;
  padding: 28px 0;
  text-align: center;
}
@media screen and (max-width: 1023px) {
  .b-promo_line {
    padding: 12px 0;
  }
}
.b-promo_line.m-search_results {
  background-color: #f2f2f2;
  color: #2f7f33;
}
@media screen and (max-width: 767px) {
  .b-promo_line.m-search_results {
    display: none;
  }
}
.m-has_dialog .b-promo_line.m-header {
  overflow-y: scroll;
}
.b-promo_line-inner {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-promo_line-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-promo_line-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-promo_line a {
  color: inherit;
  text-decoration: underline;
}
.b-promo_line a:hover {
  color: #000001;
  text-decoration: none;
}

/*md

# b-promo_tiles_grid

This is special type of banners that is provided as single component and mostly
used as set of tiles with links to categories or subcategories.

## Example

```html_example
<section class="b-promo_tiles_grid">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

*/
.b-promo_tile {
  display: grid;
  height: 100%;
}
.b-promo_tile-picture {
  background: #f8f5f5;
  display: block;
  overflow: hidden;
  padding-bottom: 142.8571428571%;
  position: relative;
  width: 100%;
  grid-column: 1/2;
  grid-row: 1/2;
}
.b-promo_tile-picture img {
  bottom: 0;
  color: #f8f5f5;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-promo_tile-caption {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1/2;
  grid-row: 1/2;
  justify-content: flex-end;
  padding: 48px 12px;
  text-align: center;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .b-promo_tile-caption {
    padding-bottom: 36px;
    padding-top: 36px;
  }
}
.b-promo_tile.m-caption_below .b-promo_tile-caption {
  grid-row: 2/2;
  padding-bottom: 20px;
  padding-top: 20px;
}
@media screen and (max-width: 767px) {
  .b-promo_tile.m-caption_below .b-promo_tile-caption {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
.b-promo_tile-caption .b-button {
  margin-top: 20px;
}
.b-promo_tile-title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.33;
}

.b-promo_tiles_grid.m-custom_menu .b-promo_tile-title {
  font-size: 16px;
}

.l-plp_grid .b-promo_tile .b-promo_tile-title {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.b-promo_tiles_grid-title {
  font-size: 27px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 40px 0 20px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .b-promo_tiles_grid-title {
    margin: 30px 0 15px;
  }
}
.b-promo_tiles_grid-content {
  display: grid;
}
@media screen and (min-width: 1367px) {
  .b-promo_tiles_grid-content {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-promo_tiles_grid-content {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-promo_tiles_grid-content {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767px) {
  .b-promo_tiles_grid-content {
    grid-gap: 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
.b-promo_tiles_grid-item {
  display: block;
  grid-column: span 3;
}
@media screen and (min-width: 768px) {
  .b-promo_tiles_grid.m-lg_2 .b-promo_tiles_grid-item {
    grid-column: span 6;
  }
  .b-promo_tiles_grid.m-lg_3 .b-promo_tiles_grid-item {
    grid-column: span 4;
  }
}
@media screen and (max-width: 767px) {
  .b-promo_tiles_grid-item {
    grid-column: span 3;
  }
  .b-promo_tiles_grid-item:not(:last-child) {
    margin-bottom: var(--spacer-mobile, 0px);
  }
  .b-promo_tiles_grid.m-sm_1 .b-promo_tiles_grid-item {
    grid-column: span 6;
  }
  .b-promo_tiles_grid.m-sm_2_1 .b-promo_tiles_grid-item:nth-child(3n) {
    grid-column: span 6;
  }
}
.b-promo_tiles_grid-item_link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  display: block;
  line-height: 1.33;
}
@media not all and (pointer: coarse) {
  .b-promo_tiles_grid-item_link:hover {
    text-decoration: underline;
  }
}

.b-promo_tiles_grid.m-custom_menu {
  margin: 8px auto 40px;
  max-width: 66.6666666667%;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .b-header_wishlist {
    align-items: center;
    appearance: none;
    background: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 48px;
    justify-content: center;
    text-align: center;
    width: 48px;
  }
  .b-header_wishlist:hover {
    color: #000001;
  }
}
@media screen and (max-width: 1023px) {
  .b-header_wishlist {
    color: inherit;
    cursor: pointer;
    text-underline-offset: 4px;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    display: flex;
    min-height: 48px;
    padding: 0 20px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    box-shadow: none;
    font-size: 14px;
    padding: 0 4px;
    text-transform: none;
  }
  @media not all and (pointer: coarse) {
    .b-header_wishlist:hover {
      text-decoration: underline;
    }
  }
}
@media not all and (pointer: coarse) {
  .b-header_wishlist svg {
    transform: translateY(0);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
  .b-header_wishlist:hover svg, .b-header_wishlist.m-hover svg {
    transform: translateY(-4px);
  }
}
.b-header_wishlist-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
  stroke: black;
}
.b-header_wishlist-icon:hover {
  color: #000001;
}
.b-header_wishlist.m-animated .b-header_wishlist-icon {
  animation: heart-bit ease-out 1s;
  animation-delay: 2s;
}
.b-header_wishlist-copy {
  display: none;
}
.b-menu_panel .b-header_wishlist-copy {
  display: block;
}