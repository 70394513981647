.b-carousel_book_scroll-widget {
	@include media(sm) {
		display: flex;
		flex-direction: column;
	}
	display: flex;
    width: 100%;
    height: 100%;

	&.ratio_4-5 {
		.b-hero_carousel-item {
			aspect-ratio: 4/5;
		}
		.b-promo_info_box {
			@include media(sm) {
				aspect-ratio: unset;
			}
			aspect-ratio: 4/5;
		}

		.b-video.video-play-full {
			aspect-ratio: 4/5;
		}

		.carousel-book {
			.b-promo_box {
				width: 60%;
				height: 60%;
			}

			a {
				width: 60%;
				height: 60%;
				display: flex;
				justify-content: center;
				align-items: center;

				.b-promo_box {
					width: 100%;
					height: 100%;
				}
			}
		}
		.b-promo_caption {
			&-title {
				top: 6%;
				@include media(md-down) {
					top: 4%;
				}
			}

			&-subtitle {
				top: 83%;
			}
		}
	}

	&.ratio_1-1 {
		.b-hero_carousel-item {
			aspect-ratio: 1/1;
		}
		.b-promo_info_box {
			@include media(sm) {
				aspect-ratio: unset;
			}
			aspect-ratio: 1/1;
		}

		.b-video.video-play-full {
			aspect-ratio: 1/1;
		}

		.carousel-book {
			.b-promo_box {
				width: 48%;
				height: 48%;
			}

			a {
				width: 48%;
				height: 48%;
				display: flex;
				justify-content: center;
				align-items: center;

				.b-promo_box {
					width: 100%;
					height: 100%;
				}
			}
		}
		.b-promo_caption {
			&-title {
				top: 10%;
				@include media(md-down) {
					top: 5%;
				}
			}

			&-subtitle {
				top: 82%;
			}
		}
	}

	.b-hero_carousel {
		@include media(sm) {
			width: 100%;
		}
		width: 50%;

		&.switch {
			order: 2;
		}
		.b-promo_caption {
			&-title {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				width: 100%;
			}

			&-subtitle {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				width: 100%;
				@include media(md-down) {
					display: block;
				}
			}
		}
		.b-hero_carousel-item.carousel-book {
			display: flex;
			justify-content: center;
			align-content: center;
			flex-wrap: wrap;
			align-items: center;
			flex-direction: column;

		}

		&[data-book-scroll-slave='true'] {
			pointer-events: none !important;

			a {
				pointer-events: auto !important;
			}
		}
	}
	.b-hero_carousel-dsk {
		@include media(sm) {
			display: none;
		}
		display: block;
	}
	.b-hero_carousel-mb {
		@include media(sm) {
			display: block;
		}
		display: none;
	}

	.second_el_wrapper {
		.b-hero_carousel-ctrl {
			display: none;
		}
		.b-hero_carousel-pagination {
			display: none;

			@include media(sm) {
				display: block;
			}
		}
	}
}
