.b-switch {
    &_country {
        .b-dialog {
            &-header {
                justify-content: center;

                .b-dialog {
                    &-title {
                        text-align: center;
                    }
                }
            }
            &-body {
                p {
                    text-align: center;
                }
            }
        }

        &-inner {
            .b-dialog-window.m-active {
                min-height: 25rem;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }
    }
}