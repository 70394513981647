.b-country_selector {
	$line-color: adjust-color-to-bg($color-bg-header-line-1, white, rgba(0, 0, 0, 27%));

	cursor: pointer;

	@include media(lg-up) {
		align-items: center;
		display: flex;
		position: relative;
	}

	&-locale {
		align-items: center;
		appearance: none;
		background: transparent;
		border: none;
		cursor: pointer;
		display: flex;
		width: 100%;

		&.m-switcher:hover {
			@include media(lg-up) {
				background: $color-bg-shade;
			}
		}
	}

	&-item {
		width: 100%;
	}

	&-switcher {
		@include media(md-down) {
			@include g-link_hamburger;

			box-shadow: none;
		}

		&:hover {
			text-decoration: none;
		}
	}

	&-locale_language {
		@include media(md-up) {
			margin: 0 12px;
		}
	}

	&-locale_icon {
		margin-inline-start: auto;
	}

	&-flyout {
		background-color: $color-bg;
		border: 1px solid invert($color-bg-header-line-1, 30);
		box-shadow: $depth-2-no-top;
		color: $color-text;
		display: none;
		left: 0;
		position: absolute;
		top: 49px;
		width: 100%;
		z-index: z(country-selector);

		&[aria-hidden='false'] {
			display: block;
		}
	}

	&-title {
		color: $color-black;
		font-size: 23px;
		font-weight: 500;
		letter-spacing: 1px;
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	&-description {
		line-height: 1.71;
		margin-bottom: 30px;
	}

	&-form {
		@include media(md-down) {
			padding: 20px 15px;
		}
	}

	&-inner {
		@include g-sliding_panel;

		box-shadow: none;
		left: 0;
		margin: 0;
		max-width: 459px;
		padding: 40px;
		transform: translateX(-100%);

		&.m-active {
			transform: translateX(0);
		}
	}

	&-container {
		@include g-dialog_backdrop;

		&.m-opened {
			overflow: hidden;
		}
	}
}
