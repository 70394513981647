.l-page {
	.m-has_dialog & {
		overflow: hidden;
	}

	&-content,
	&-footer { // we need a wrapper to handle scroll / no scroll content width bumping
		.m-has_dialog & {
			overflow-y: scroll;
		}
	}
}
