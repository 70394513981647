.b-logo {
	color: adjust-color-to-bg($color-bg-header-line-1);
	display: block;
	margin: auto;
	max-width: 300px;
	min-width: 200px;

	@include media(lg-up) {
		.b-header_stuck & {
			color: adjust-color-to-bg($color-bg-header-line-2);
		}
	}

	@include media(sm) {
		max-width: 158px;
		min-width: 158px;
	}

	svg {
		max-width: 100%;

		@include media(md-down) {
			max-height: 40px;
		}
	}

	// need only for themes. Add theme primary color as color of dot in logo
	&-dot {
		fill: if($color-primary == $color-blue, $color-primary, $color-accent); // stylelint-disable-line
	}
}
