.b-search_toggle {
	$line-color: adjust-color-to-bg($color-bg-header-line-1, white, rgba(0, 0, 0, 27%)); // color-grey73

	align-items: center;
	color: adjust-color-to-bg($color-bg-header-line-1, white, rgba(0, 0, 0, 54%)); // color-grey46
	cursor: pointer;
	display: flex;
	font-size: 16px;
	text-align: start;

	@include media(md-down) {
		font-size: 0;
	}

	@include hover-supported {
		svg {
			transform: translateY(0);
			transition: transform $motion-ease;
		}

		&:hover,
		&.m-hover {
			svg {
				transform: translateY(-4px);
			}
		}
	}

	.b-header_stuck & {
		font-size: 0;
	}

	&-icon {
		@include g-button_iconed(40px, 40px);

		color: adjust-color-to-bg($color-bg-header-line-1);
		transition: color;

		@include media(lg-up) {
			height: 48px;
			width: 48px;
		}

		.b-header_stuck & {
			margin-inline-end: 0;

			&:hover {
				background: none;
				color: $color-action;
			}
		}
	}
}
