/*md

# b-breadcrumbs

Breadcrumbs navigation is represented as a list of links.

The last item of breadcrumbs list (e.g. "Level 4" link) is hidden. It links to the current page and is only needed for accessibility purposes.

```html_example
<nav class="b-breadcrumbs" aria-label="Breadcrumbs">
    <ul class="b-breadcrumbs-list">
    	<li class="b-breadcrumbs-item">
    	    <a class="b-breadcrumbs-link" href="">Homepage</a>
    	</li>
    	<li class="b-breadcrumbs-item">
    	    <svg class="b-breadcrumbs-icon" aria-hidden="true" width="9" height="9" focusable="false">
    	        <use href="#arrow-down-small"></use>
    	    </svg>
    	    <a class="b-breadcrumbs-link" href="">Link 1</a>
    	</li>
    	<li class="b-breadcrumbs-item">
    	    <svg class="b-breadcrumbs-icon" aria-hidden="true" width="9" height="9" focusable="false">
    	        <use href="#arrow-down-small"></use>
    	    </svg>
    	    <a class="b-breadcrumbs-link" href="">Link 2</a>
    	</li>
    	<li class="b-breadcrumbs-item">
    	    <svg class="b-breadcrumbs-icon" aria-hidden="true" width="9" height="9" focusable="false">
    	       <use href="#arrow-down-small"></use>
    	    </svg>
    	    <a class="b-breadcrumbs-link" href="">Link 3</a>
    	</li>
    </ul>
</nav>

<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <symbol id="arrow-down-small" viewBox="0 0 10 6">
        <path fill="currentColor" d="M5 6c-.256 0-.512-.098-.707-.293l-4-4c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l3.305 3.305L8.305.418c.4-.383 1.03-.372 1.414.025.384.397.373 1.031-.024 1.414l-4 3.862C5.5 5.907 5.25 6 5 6"></path>
    </symbol>
</svg>
```
*/

.b-breadcrumbs {
	overflow-x: auto;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	&-list {
		display: flex;
	}

	&-item {
		margin-inline-end: rh(1);
		white-space: nowrap;

		&:not(:nth-last-child(2)) {
			@include media(sm) {
				display: flex;
				margin: 0;
				opacity: 0;
				width: 0;
				z-index: -1;
			}
		}

		&:last-child {
			opacity: 0;
			z-index: -1;

			&:focus-within {
				display: initial;
				opacity: 1;
				width: auto;
			}
		}
	}

	&-icon {
		color: $color-black;
		transform: rotate(270deg);

		@include media(sm) {
			transform: rotate(90deg);
		}

		@include rtl {
			transform: rotate(90deg);
		}
	}

	&-link {
		@include g-link;

		color: adjust-color-to-bg($color-bg);
		display: inline-block;
		font-size: 12px;
		font-weight: 500;
		position: relative;
		text-decoration: underline;
		transition: $motion-fast;
		transition-property: color;
		white-space: nowrap;

		&:hover {
			text-decoration: none;
		}

		&[aria-current='page'] {
			cursor: default;
			pointer-events: none; // it should be link for ARIA and Schema.org requirements
			text-decoration: none;

			@include media(sm) {
				display: inline-block;
			}
		}
	}
}
