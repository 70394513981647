.b-carousel_and_text_wrapper {
	@include media(sm) {
		display: block;
	}
	display: flex;
    width: 100%;
    height: 100%;

	&.ratio_16-9 {
		.b-hero_carousel-item {
			aspect-ratio: 16/9;
		}
		.b-promo_info_box {
			@include media(sm) {
				aspect-ratio: unset;
			}
			aspect-ratio: 16/9;
		}

		.b-video {
			@include media(sm) {
				aspect-ratio: inherit;
			}
			aspect-ratio: 16/9;
			.b-video-controls-wrapper {
				@include media(sm) {
					top: 172px;
					bottom: unset;
				}
				bottom: 30px;
			}
		}
		.b-promo_box.m-full_bleed .b-promo_box-picture {
			@include media(sm) {
				padding-bottom: aspect-ratio(16, 9);
			}
		}
		figure {
			picture {
				img {
					object-fit: contain;
				}
			}
		}
	}

	&.ratio_4-5 {
		.b-hero_carousel-item {
			aspect-ratio: 4/5;
		}
		.b-promo_info_box {
			@include media(sm) {
				aspect-ratio: unset;
			}
			aspect-ratio: 4/5;
		}

		.b-video {
			@include media(sm) {
				aspect-ratio: inherit;
			}
			aspect-ratio: 4/5;
			.b-video-controls-wrapper {
				bottom: 25px;
			}
		}
	}

	&.ratio_1-1 {
		.b-hero_carousel-item {
			aspect-ratio: 1/1;
		}
		.b-promo_info_box {
			@include media(sm) {
				aspect-ratio: unset;
			}
			aspect-ratio: 1/1;
		}

		.b-video {
			@include media(sm) {
				aspect-ratio: inherit;
			}
			aspect-ratio: 1/1;

			.b-video-controls-wrapper {
				bottom: 20px;
			}
		}
	}

	.b-hero_carousel {
		@include media(sm) {
			width: 100%;
		}
		width: 50%;

		&.switch {
			order: 2;
		}
	}
	.second_el_wrapper {
		@include media(sm) {
			width: 100%;
		}
		width: 50%;
	}
}