.b-header_message {
	font-size: 15px;
	font-weight: 500;
	padding: 8px 0;
	text-align: center;
	visibility: visible;

	&.m-error {
		background-color: $color-error;
		color: $color-white;
	}

	&-inner {
		@include g-section_holder;

		max-width: 960px;
	}

	a {
		color: inherit;
		text-decoration: underline;
	}
}
