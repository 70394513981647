.b-badges {
	align-items: flex-start;
	display: flex;
	flex-flow: column;
	left: 15px;
	max-width: calc(100% - 156px);
	position: absolute;
	top: 15px;
	z-index: 1;

	&-item {
		background-color: $color-soft-beige-2;
		border-radius: 2px;
		color: $color-black;
		font-size: 12px;
		line-height: 1;
		padding: 5px 10px;
		text-transform: uppercase;
		word-break: break-word;
	}

	&-item + &-item {
		margin-top: rh(1);
	}

	.b-product_tile &,
	.b-carousel & {
		left: auto;
		max-width: calc(100% - 68px);
		right: 10px;
		top: 10px;
	}

	.b-wishlist_tile & {
		left: 10px;
		max-width: calc(100% - 68px);
		top: 10px;
	}
}
