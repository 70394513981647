.l-header {
	background: $color-bg-header-line-1;
	padding: 1px 0; // prevent margin collapse	

	@include media(md-down) {
		position: sticky;
		top: -0.1px; // scale rounding fix for devices with 1.25 ratio
		z-index: z(header);
	}

	.m-has_dialog & { // we need a wrapper to handle scroll / no scroll content width bumping
		overflow-y: scroll;
	}

	&.m-simple {
		box-shadow: 0 1px 0 0 $color-divider;
		font-size: 12px;
		font-weight: 700;

		@include media(md-down) {
			position: static;
		}
	}

	&-inner {
		@include g-section_holder_header;

		align-items: center;
		display: flex;
		justify-content: space-between;
		margin: 7px auto;
		position: relative;
		width: 100%;

		@include media(lg-up) {
			height: 60px;
			margin: rh(3) auto 0;
		}

		.l-header.m-simple & {
			margin: 32px auto;

			@include media(sm) {
				flex-direction: row-reverse;
				margin: 16px auto;
			}
		}
	}

	&-left {
		width: 50%;

		@include media(md-down) {
			width: auto;
		}

		.l-header.m-simple & {
			@include media(md) {
				width: 50%;
			}
		}
	}

	&-middle {
		display: flex;
		height: 100%;
		left: 50%;
		position: absolute;
		top: 0;
		transform: translateX(-50%);

		.l-header.m-simple & {
			@include media(md-down) {
				position: static;
				transform: none;
			}
		}
	}

	&-right {
		display: flex;
		justify-content: flex-end;

		.l-header.m-simple & {
			@include media(md) {
				width: 50%;
			}

			@include media(sm) {
				display: none;
			}
		}
	}
}
