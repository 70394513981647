.b-find_store {
	$button-height: 48px;

	height: $button-height;
	width: $button-height;

	@include media(md-up) {
		align-items: center;
		color: adjust-color-to-bg($color-bg-footer-region-2);
		display: inline-flex;
		justify-content: center;
	}

	@include hover-supported {
		svg {
			transform: translateY(0);
			transition: transform $motion-ease;
		}

		&:hover,
		&.m-hover {
			svg {
				transform: translateY(-4px);
			}
		}
	}

	svg {
		height: 16px;
		width: 12px;
	}

	.b-header_actions-item & {
		svg {
			color: adjust-color-to-bg($color-bg-header-line-1);
		}
	}

	.b-menu_panel-footer & {
		@include g-link_hamburger;

		border: none;
		box-shadow: none;
		justify-content: flex-start;
		padding: 0 4px;
		text-transform: none;

		&-icon {
			@include g-button_iconed(40px, 40px);
		}
	}
}
