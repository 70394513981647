.b-header_button {
	@include g-button_iconed(40px, 40px);

	@include media(lg-up) {
		height: 48px;
		width: 48px;
	}

	&.m-hamburger {
		svg {
			height: 16px;
			width: 20px;
		}
	}
}
