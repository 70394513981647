.b-flyout_promo {
	@include media(md-down) {
		display: none;
	}

	@include media(lg-up) {
		display: block;
		padding-bottom: 20px;
		position: relative;
		width: 408px;
	}

	&.m-fullwidth {
		margin: 0 auto;
		max-width: 836px;
		width: 100%;
	}

	&-picture {
		@include g-image_container(_container, 75%);

		.m-fullwidth & {
			padding-bottom: 39.95%;
		}
	}

	&-img {
		@include g-image_container(_img);
	}

	&-title {
		@include g-heading_3;
	}

	&-actions {
		margin-top: 10px;

		a {
			padding-left: 40px;
			padding-right: 40px;
		}
	}

	&-caption {
		bottom: 0;
		left: 0;
		padding: 30px 30px 50px;
		position: absolute;
		right: 0;
		text-align: center;
		text-decoration: none;
	}
}
