.b-minicart_icon {
	&-link {
		position: relative;

		@include hover-supported {
			svg {
				transform: translateY(0);
				transition: transform $motion-ease;
			}

			&:hover,
			&.m-hover {
				svg {
					transform: translateY(-4px);
				}

				.b-minicart_icon-qty {
					top: calc(50% - 1px);
				}
			}
		}
	}

	&-qty {
		font-size: 10px;
		left: 50%;
		line-height: 1.2;
		position: absolute;
		text-align: center;
		top: calc(50% + 3px);
		transform: translate(-50%, -50%);
		transition: top $motion-ease;
	}
}
