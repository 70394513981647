.b-menu_item {
	&-link {
		@include g-link;

		display: inline-block;
		position: relative;
		text-decoration: none;

		@include media(md-down) {
			@include g-link_hamburger($_divider: true);
		}

		&.m-has-submenu { // works as title
			@include media(lg-up) {
				letter-spacing: 1px;
				margin-bottom: 12px;
				text-transform: uppercase;
			}
		}

		&.m-highlight {
			color: $color-accent;

			&:hover {
				color: $color-action;
			}
		}

		&.m-all_link {
			letter-spacing: 1px;
			margin-top: 5px;
			text-transform: uppercase;

			@include media(lg-up) {
				display: none;
			}
		}

		&.m-all_link_2nd {
			@include media(lg-up) {
				display: none;
			}
		}
	}

	&-submenu {
		font-weight: 300;
		line-height: 32px;
	}

	&-link_icon {
		margin-inline-start: auto;

		@include media(lg-up) {
			display: none;
		}

		// Custom case. We do not show custom menu on mobile, so we do not need
		// to show arrow also
		.b-menu_bar-link.m-custom_menu & {
			display: none;
		}
	}

	&.m-hidden {
		display: none;
	}
}
