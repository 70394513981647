.b-hero_carousel {
	margin: 0 auto;
	max-width: $global-site-width-max;
	overflow: hidden;
	position: relative;

	&-track {
		display: flex;
		overflow: hidden;

		&.m-grabbing {
			cursor: grab;
			user-select: none;

			&::before {
				bottom: 0;
				content: '';
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: z(components, carousel, grabbing-overlay);
			}
		}
	}

	&-item {
		height: 100%;
		left: 0;
		min-width: 100%; // until JS loaded it is show single slide. This need to flex packing
		top: 0;
		width: 100%;
		will-change: transform;
		z-index: z(components, carousel, item);

		&:not(:first-child) {
			visibility: hidden;
		}

		.b-hero_carousel.m-initialized & {
			overflow: hidden;
			position: absolute;
			transition: cubic-bezier(0.56, 0.03, 0.47, 0.98) 0.8s;
			transition-property: transform, visibility;
			visibility: hidden;
		}

		&.m-prev {
			.b-hero_carousel.m-initialized & {
				transform: translateX(-100%);
			}
		}

		&.m-next {
			.b-hero_carousel.m-initialized & {
				transform: translateX(100%);
			}
		}

		&.m-current {
			.b-hero_carousel.m-initialized & {
				position: static;
				transform: translateX(0);
				visibility: visible;
			}
		}
	}

	&-ctrl {
		appearance: none;
		border: none;
		color: $color-white;
		cursor: pointer;
		display: none;
		height: 48px;
		margin-top: -24px;
		position: absolute;
		text-align: center;
		top: 50%;
		transition: $motion-ease;
		transition-property: background-color, opacity;
		user-select: none;
		width: 48px;
		z-index: z(components, carousel, controls);

		@include hover-supported {
			svg {
				transform: translateY(0);
				transition: transform $motion-ease;
			}

			&:hover,
			&.m-hover {
				svg {
					transform: translateY(-4px);
				}
			}
		}

		.b-hero_carousel.m-initialized & {
			display: block;

			@include media(sm) {
				display: none;
			}
		}

		&.m-prev {
			left: 16px;
			&.black-arrow {
				color: $color-black;
			}
		}

		&.m-next {
			right: 16px;
			&.black-arrow {
				color: $color-black;
			}
		}
	}

	&-pagination {
		bottom: 12px;
		display: flex;
		justify-content: center;
		left: 50%;
		opacity: 0;
		position: absolute;
		transform: translateX(-50%);
		transition: opacity $motion-ease;
		visibility: hidden;
		z-index: z(components, carousel, controls);

		&.m-initialized {
			opacity: 1;
			visibility: visible;
		}
	}

	&-pagination_dots {
		display: flex;
		margin: 0 rh(1) 0 0;
	}

	&-pagination_content {
		align-items: center;
		display: flex;
		&.black-dots {
			.b-hero_carousel-pagination_dot {
				fill: $color-black;
			}
			.b-hero_carousel-pagination_svg_dot_outline {
				stroke: $color-black;
			}
		}
		&.white-dots {
			.b-hero_carousel-pagination_dot {
				fill: $color-white;
			}
			.b-hero_carousel-pagination_svg_dot_outline {
				stroke: $color-white;
			}
		}
	}

	&-pagination_dot {
		$dot-size: 17px;

		border: none;
		color: transparent;
		cursor: pointer;
		display: block;
		fill: $color-white;
		height: $dot-size;
		line-height: 1;
		margin-inline-end: 6px;
		padding: 0;
		position: relative;
		width: $dot-size;

		@include hover-supported {
			svg {
				transform: translateY(0);
				transition: transform $motion-ease;
			}

			&:not(.m-current):hover,
			&:not(.m-current):hover.m-hover {
				svg {
					transform: translateY(-4px);
				}
			}
		}

		&:last-child {
			margin-inline-end: 0;
		}

		&[aria-disabled='true'] {
			cursor: initial;
		}
	}

	&-pagination_svg_dot {
		.m-current & {
			display: none;
		}
	}

	&-pagination_svg_dot_outline {
		display: none;
		stroke: $color-white;
		stroke-width: 1;
		transition: 0.4s ease;
		transition-property: stroke-width;

		.m-current & {
			display: block;
		}
	}

	&-autoplay {
		background: transparent;
		border: none;
		border-radius: 50%;
		cursor: pointer;
		display: none;
		margin-inline-end: rh(1);
		padding: 0;

		&.m-initialized {
			display: block;
		}

		&.m-animated {
			animation: hero-carousel-progress linear;
		}
	}

	&-autoplay_svg {
		fill: transparent;
		stroke-linecap: round;
		stroke-width: 1;
	}

	&-autoplay_progress {
		stroke: $color-white;
		stroke-width: 3;

		.b-hero_carousel-autoplay.m-animated & {
			stroke: $color-grey-25;
		}
	}

	&-autoplay_progress_back {
		stroke: $color-white;
		stroke-width: 3;
	}

	&-autoplay_play,
	&-autoplay_pause {
		display: block;
		fill: $color-white;
		opacity: 1;
		stroke: $color-white;
		transition: opacity ease 0.2s;

		@include hover-supported {
			.b-hero_carousel-autoplay:hover & {
				fill: $color-soft-beige;
				stroke: $color-soft-beige;
			}
		}
	}

	&-autoplay_play {
		.b-hero_carousel-autoplay[aria-pressed='false'] & {
			opacity: 0;
		}
	}

	&-autoplay_pause {
		stroke-width: 3;

		.b-hero_carousel-autoplay[aria-pressed='true'] & {
			opacity: 0;
		}
	}

	&-autoplay {
		&.black-dots {
			.b-hero_carousel {
				&-autoplay_progress {
					stroke: $color-black;
					.b-hero_carousel-autoplay.m-animated & {
						stroke: $color-grey-50;
					}
				}
				&-autoplay_progress_back {
					stroke: $color-grey-50;
				}
		
				&-autoplay_play,
				&-autoplay_pause {
					fill: $color-black;
					stroke: $color-black;

					@include hover-supported {
						.b-hero_carousel-autoplay:hover & {
							fill: $color-soft-beige;
							stroke: $color-soft-beige;
						}
					}
				}
			}
		}
	}
}
