.b-header_utility {
	background-color: $color-bg-utility-bar;
	color: $color-almost-black;
	display: flex; // to fix child align-items bug with min-height in IE

	.m-has_dialog & { // we need a wrapper to handle scroll / no scroll content width bumping
		overflow-y: scroll;
	}

	&-inner {
		@include g-section_holder_header;

		align-items: center;
		display: flex;
		justify-content: space-between;
		min-height: 35px;
		width: 100%;

		&.pre-header {
			justify-content: center;
			font-size: 12px;
    		font-weight: 300;
    		letter-spacing: .5px;
    		margin: auto;

			span {
				transition: opacity 500ms;
				opacity: 0;

				a {
					text-decoration: none;
				}

				&.show {
					opacity: 1;
				}

				&:not(:only-child):not(.show) {
					display: none;
				}

				&:hover {
					a {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&-item {
		&.m-promo_slot {
			font-size: 12px;
			font-weight: 300;
			letter-spacing: 0.5px;
			margin: auto;

			@include media(md-down) {
				font-size: 12px;
			}
		}
	}
}
