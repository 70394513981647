// General site styles
// Be very carefully since it affects all elements and styles!
@use 'sass:list';

html {
	background: $color-bg;
	color: $color-text;
	direction: ltr;
	font: list.slash($size-font, $size-line) $font-sans;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-synthesis: none;
	scroll-behavior: smooth;
	-ms-text-size-adjust: 100%; // stylelint-disable-line
	-webkit-text-size-adjust: 100%; // stylelint-disable-line
}

body {
	box-sizing: border-box;
	margin: 0;
	min-width: $global-content-width-min;
	overflow-y: scroll;
	padding: 0.01px 0 0; // Android UI status bar overlap browser window. Reproducible on Samsung S9 Chrome

	@include rtl {
		direction: rtl;
		letter-spacing: 0;
	}
}

a {
	@include g-link(underlined);
}

button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	padding: 0;
}

img {
	overflow: hidden;
}

// Use to show anchor elements down below sticky header
:target {
	&::before {
		content: '';
		display: block;
		height: 65px;
		margin-top: -65px;
		visibility: hidden;
	}
}

// Hide element on screen, but make it accessible for AT
.b-sr_only {
	@include hide(visually);
}

.text-decoration-none{
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-serif;
}

.font-georgia {
	font-family: $font-serif !important;
}

.font-roboto {
	font-family: $font-roboto !important;
}

.grecaptcha-badge { visibility: hidden; }
