/*md

# b-carousel (based on scroll)

## Carousel examples

### 1. Hero carousel

```html_example
<div data-widget="carousel" class="b-carousel m-hero">
    <button class="b-carousel-ctrl m-prev" tabindex="-1" aria-hidden="true" title="Previous" data-ref="elemPrevButton" data-event-click="scrollToPrevPage">
        <svg width="40" height="40" viewBox="2 1 40 40" aria-hidden="true" focusable="false">
            <path d="m26.541 8.3536-12.728 12.728 12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
        </svg>
    </button>
    <button class="b-carousel-ctrl m-next" tabindex="-1" aria-hidden="true" title="Next" data-ref="elemNextButton" data-event-click="scrollToNextPage">
        <svg width="40" height="40" viewBox="-1 1 40 40" aria-hidden="true" focusable="false">
            <path d="m13.459 8.3536 12.728 12.728-12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
        </svg>
    </button>
    <div class="b-carousel-track" data-ref="elemCarouselTrack" data-event-scroll.passive="onScroll" data-event-touchstart="onScroll" data-event-mousedown.prevent="onMouseDown" data-event-mouseup="onMouseUp">
        <div class="b-carousel-item">
            <figure class="b-promo_box m-full_bleed m-caption_offcenter">
                <picture class="b-promo_box-picture">
                    <img src="../images/guide/examples/hero-carousel-banner-1.jpg?$staticlink$" alt="Girl stand on the beach." loading="eager" width="1600" height="800">
                </picture>
                <figcaption class="b-promo_box-inner">
                    <div class="b-promo_box-caption b-promo_caption">
                        <h2 class="b-promo_caption-title">Carousel first slide</h2>
                        <p class="b-promo_caption-subtitle">Everyone's fallen for boilerplate so we added to her games repertoire.</p>
                        <div class="b-promo_caption-actions">
                            <a class="b-button" href="#" aria-label="Shop new season at boilerplate">Shop New Season</a>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </div>
        <div class="b-carousel-item">
            <figure class="b-promo_box m-full_bleed m-caption_offcenter">
                <picture class="b-promo_box-picture">
                    <img src="../images/guide/examples/hero-carousel-banner-2.jpg?$staticlink$" alt="Girl with khaki panama." loading="lazy" width="1600" height="800">
                </picture>
                <figcaption class="b-promo_box-inner">
                    <div class="b-promo_box-caption b-promo_caption">
                        <h2 class="b-promo_caption-title">Carousel second slide</h2>
                        <p class="b-promo_caption-subtitle">New playful styles inspired by darts and that staple of a British pub - the fruit machine.</p>
                        <div class="b-promo_caption-actions">
                            <a class="b-button" href="#" aria-label="Shop new Collection at boilerplate">Shop New Collection</a>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </div>
        <div class="b-carousel-item">
            <figure class="b-promo_box m-full_bleed m-caption_offcenter">
                <picture class="b-promo_box-picture">
                    <img src="../images/guide/examples/hero-carousel-banner-3.jpg?$staticlink$" alt="Girl stand in cloak." loading="lazy" width="1600" height="800">
                </picture>
                <figcaption class="b-promo_box-inner">
                    <div class="b-promo_box-caption b-promo_caption">
                        <h2 class="b-promo_caption-title">Carousel third slide</h2>
                        <div class="b-promo_caption-actions">
                            <a class="b-button" href="#" aria-label="Shop new Season at boilerplate">Shop New Season</a>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </div>
    </div>
    <div aria-hidden="true" class="b-carousel-pagination" data-ref="pagination">
        <button class="b-carousel-page" data-page="0" tabindex="-1" data-event-click.prevent="handlePaginationClick"></button>
        <button class="b-carousel-page" data-page="1" tabindex="-1" data-event-click.prevent="handlePaginationClick"></button>
        <button class="b-carousel-page" data-page="2" tabindex="-1" data-event-click.prevent="handlePaginationClick"></button>
    </div>
</div>
```

### 2. Products carousel

```html_example
<section data-widget="carousel" class="b-carousel m-products">
    <h2 class="b-carousel-title">Look what's new</h2>
    <button class="b-carousel-ctrl m-prev" tabindex="-1" aria-hidden="true" title="Previous" data-ref="elemPrevButton" data-event-click="scrollToPrevPage">
        <svg width="40" height="40" viewBox="2 1 40 40" aria-hidden="true" focusable="false">
            <path d="m26.541 8.3536-12.728 12.728 12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
        </svg>
    </button>
    <button class="b-carousel-ctrl m-next" tabindex="-1" aria-hidden="true" title="Next" data-ref="elemNextButton" data-event-click="scrollToNextPage">
        <svg width="40" height="40" viewBox="-1 1 40 40" aria-hidden="true" focusable="false">
            <path d="m13.459 8.3536 12.728 12.728-12.728 12.728" fill="none" stroke="currentColor" stroke-width="2"></path>
        </svg>
    </button>
    <div class="b-carousel-track" data-ref="elemCarouselTrack" data-event-scroll.passive="onScroll" data-event-touchstart="onScroll" data-event-mousedown.prevent="onMouseDown" data-event-mouseup="onMouseUp">
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
        <div class="b-carousel-item">
            <section class="b-product_tile">
                <img src="../images/guide/examples/product-carousel-image.png?$staticlink$" width="260" height="346">
            </section>
        </div>
    </div>
</section>
<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <symbol id="star">
        <path d="m13.344 15.662-5.0953-2.6691-5.0873 2.6842 0.96393-5.6707-4.1249-4.0089 5.691-0.83558 2.538-5.1618 2.5533 5.1543 5.6935 0.81868-4.113 4.0211z"></path>
    </symbol>
</svg>
```
*/

@use 'sass:math';

.b-carousel {
	position: relative;

	&-title {
		@include g-heading_2;

		margin-bottom: 20px;
		text-align: center;

		@include media(sm) {
			font-size: 24px;
			margin-bottom: 15px;
		}
	}

	&-track {
		@include g-snap_scroll;

		&.m-mousemove_navigation {
			scroll-snap-type: unset;
		}

		&.m-grabbing {
			cursor: grab;
			scroll-behavior: auto;
			user-select: none;

			&::before {
				bottom: 0;
				content: '';
				display: block;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: z(components, carousel, grabbing-overlay);
			}
		}
	}

	&-item {
		scroll-snap-align: start;
	}

	// Prev / Next
	&-ctrl {
		appearance: none;
		background-color: rgba($color-white, 0.26);
		border: none;
		color: $color-text;
		cursor: pointer;
		display: none;
		height: 40px;
		margin-top: -24px;
		position: absolute;
		text-align: center;
		top: 50%;
		transition: $motion-ease;
		transition-property: background-color, opacity;
		user-select: none;
		width: 40px;
		z-index: z(components, carousel, controls);

		@include hover-supported {
			&:hover {
				background-color: $color-white;
			}
		}

		.b-carousel.m-inited & {
			display: block;

			@include media(sm) {
				display: none;
			}
		}

		&[disabled],
		.b-carousel.m-no_scroll & {
			// It should not be hidden with display: none
			// 1) screen reader should announce "Unavailable" to user
			// 2) we should prevent of user false clicks when button is disappears

			opacity: 0;
			z-index: -1;
		}

		&.m-prev {
			left: 0;
		}

		&.m-next {
			right: 0;
		}

		path {
			stroke-width: 1;
		}
	}

	// Pagination. Used only on HP Hero
	&-pagination {
		bottom: rh(4);
		display: none;
		justify-content: center;
		left: grid-gutter(sm);
		position: absolute;
		right: grid-gutter(sm);
		z-index: z(components, carousel, controls);

		@include media(sm) {
			justify-content: flex-start;
		}

		.b-carousel.m-inited & {
			display: flex;
		}

		.b-carousel.m-no_scroll & {
			display: none;
		}
	}

	&-page {
		background-color: transparent;
		border: none;
		cursor: pointer;
		display: block;
		height: 20px;
		line-height: 20px;
		position: relative;
		text-align: center;
		width: 20px;

		&::before {
			$dot-size: 8px;

			background-color: $color-text;
			border: 1px solid $color-white;
			border-radius: $dot-size;
			content: '';
			display: inline-block;
			height: $dot-size;
			left: 50%;
			margin-inline-start: -#{math.div($dot-size, 2)};
			margin-top: -#{math.div($dot-size, 2)};
			position: absolute;
			top: 50%;
			transition: $motion-ease;
			transition-property: width, height, margin;
			width: $dot-size;
		}

		&.m-current {
			&::before {
				background-color: $color-action-obj;
				content: '';
				height: 14px;
				margin-inline-start: -7px;
				margin-top: -7px;
				width: 14px;
			}
		}
	}
}

.b-carousel.m-products,
.b-carousel.m-recently_viewed_products {
	margin: 40px 0;

	@include media(sm) {
		margin: 30px 0;
	}

	.b-carousel-ctrl {
		&.m-prev {
			left: -40px;
		}

		&.m-next {
			right: -40px;
		}
	}

	.b-carousel-item {
		margin-inline-end: 20px;
		max-width: 15.956%;
		min-width: 15.956%;

		@include media(md) {
			max-width: 30%;
			min-width: 30%;
		}

		@include media(sm) {
			max-width: 58.56%;
			min-width: 58.56%;
		}
	}
}

.b-carousel.m-recently_viewed_products {
	margin: rh(10 0);

	.b-carousel-ctrl {
		&.m-prev { // stylelint-disable-line no-descending-specificity
			left: -40px;
		}

		&.m-next { // stylelint-disable-line no-descending-specificity
			right: -40px;
		}
	}

	.b-carousel-item {
		margin-inline-end: 20px;
		max-width: 15.956%;
		min-width: 15.956%;

		@include media(md) {
			max-width: 25%;
			min-width: 25%;
		}

		@include media(sm) {
			max-width: 58.56%;
			min-width: 58.56%;
		}
	}
}

.b-carousel.m-hero {
	margin: 0 auto;
	max-width: $global-site-width-max;

	.b-carousel-ctrl {
		&.m-prev { // stylelint-disable-line no-descending-specificity
			left: rh(10);
		}

		&.m-next { // stylelint-disable-line no-descending-specificity
			right: rh(10);
		}
	}

	.b-carousel-item {
		$width: 100%;

		max-width: $width;
		min-width: $width;
	}
}
